import moment from 'moment-timezone';
import UAParser from 'ua-parser-js';
import store from '../store/index';
import constant from '../const/index';
import { sciToDec } from './scientific-to-decimal';
import { getRouteAccessibility, getSectionAccessibility } from './check-access-rights';
import {
  getCaption,
  getFullDate,
  isIdentificatorType,
  onlyNumbersHandler,
  getUserTimezone,
} from './common';

export default {
  getDate: (value) => moment(value).format('DD.MM.YYYY'),

  getControlableDate: (date, userTimezone) => {
    // Если предоставлена временная зона пользователя, форматируем дату в локальном времени пользователя
    if (userTimezone) {
      return moment(date).format('DD.MM.YYYY');
    }
    // Если временная зона пользователя не предоставлена, форматируем дату в UTC
    return moment.utc(date).format('DD.MM.YYYY');
  },

  getMonthAndDay: (value) => {
    // Форматируем дату, чтобы отобразить только день и месяц в формате DD.MM
    return moment(value).format('DD.MM');
  },

  getUnixFullDateAndTime: (value) => {
    // Преобразуем Unix-время (в секундах) в объект даты
    const date = new Date(value * 1000);
    // Извлекаем и форматируем день
    const day = date.getDate().toString().padStart(2, '0');
    // Извлекаем и форматируем месяц
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    // Извлекаем год
    const year = date.getFullYear();
    // Извлекаем и форматируем часы
    const hours = date.getHours().toString().padStart(2, '0');
    // Извлекаем и форматируем минуты
    const minutes = date.getMinutes().toString().padStart(2, '0');
    // Извлекаем и форматируем секунды
    const seconds = date.getSeconds().toString().padStart(2, '0');
    // Возвращаем дату и время в формате DD.MM.YYYY HH:mm:ss
    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  },

  getUnixDate: (value) => {
    // Преобразуем Unix-время (в секундах) в объект даты
    const date = new Date(value * 1000);
    // Извлекаем и форматируем день
    const day = date.getDate().toString().padStart(2, '0');
    // Извлекаем и форматируем месяц
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    // Извлекаем год
    const year = date.getFullYear();
    // Возвращаем дату в формате DD.MM.YYYY
    return `${day}.${month}.${year}`;
  },

  getUnixMonthAndDay: (value) => {
    // Преобразуем Unix-время (в секундах) в объект даты
    const date = new Date(value * 1000);
    // Извлекаем и форматируем день
    const day = date.getDate().toString().padStart(2, '0');
    // Извлекаем и форматируем месяц
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    // Возвращаем день и месяц в формате DD.MM
    return `${day}.${month}`;
  },

  getUnixTime: (value) => {
    // Преобразуем Unix-время (в секундах) в объект даты
    const date = new Date(value * 1000);
    // Извлекаем и форматируем часы
    const hours = date.getHours().toString().padStart(2, '0');
    // Извлекаем и форматируем минуты
    const minutes = date.getMinutes().toString().padStart(2, '0');
    // Извлекаем и форматируем секунды
    const seconds = date.getSeconds().toString().padStart(2, '0');
    // Возвращаем время в формате HH:mm:ss
    return `${hours}:${minutes}:${seconds}`;
  },

  convertExponentialToDecimal: (exponentialNumber) => {
    // Преобразуем экспоненциальное число в строку
    const str = exponentialNumber.toString();
    // Находим индекс символа 'e' (экспоненты) в строке
    const eIndex = str.indexOf('e');
    // Если индекс равен -1, значит, символ 'e' не найден и число не является экспонентой
    // В этом случае возвращаем исходное число без изменений
    if (eIndex !== -1) {
      // Иначе, число является экспонентой
      // Заменяем символ 'e' (экспоненты) на точку '.', чтобы преобразовать экспоненциальное число в десятичное
      // Затем обрезаем строку так, чтобы оставить только нужное количество цифр после точки
      // Это делается путем нахождения позиции, до которой нужно обрезать строку
      // Позиция вычисляется как индекс символа 'e' плюс показатель степени плюс 2 (для двух дополнительных цифр после точки)
      return +str.replace('e', '.').slice(0, eIndex + +str.slice(eIndex + 1) + 2);
      // Перед str.replace() стоит унарный плюс '+', который преобразует строку обратно в число
      // Это необходимо, потому что replace() возвращает строку, а нам нужно вернуть число
    }
    return exponentialNumber;
  },

  getAppType: () => {
    // Получаем тип приложения
    return process.env?.VUE_APP_TYPE;
  },

  getAppVersion: () => {
    // Получаем версию приложения
    return Number(process.env.VUE_APP_P2PAY_VERSION);
  },

  capitalizer: (string) => {
    // Преобразуем первый символ строки в верхний регистр
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  getTime: (value) => moment(value).format('HH:mm:ss'),

  getControlableTime: (time, userTimezone) => {
    // Если предоставлена временная зона пользователя, форматируем время в локальном времени пользователя
    if (userTimezone) {
      return moment(time).format('HH:mm:ss');
    }
    return moment.utc(time).format('HH:mm:ss');
  },

  getParsedUA: (value) => UAParser(value),

  getMarketById: (value) => {
    // Получаем маркет по ID
    const { markets } = store.state.currencies;
    const market = markets.find((e) => e.id === value);
    return market;
  },

  getOrderTypeById: (value) => {
    // Получаем тип ордера по ID
    const { ORDER_TYPE_LIST } = constant.orders;
    const orderType = ORDER_TYPE_LIST.find((e) => e.id === value);
    return orderType;
  },

  downloadReport: async (data, file) => {
    // Формируем фантомную кнопку для скачивания файла отчета
    const objectUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = objectUrl;
    link.setAttribute('download', file);
    document.body.appendChild(link);
    link.click();
  },

  // Преобразует Base64 строку в Blob объект с указанным MIME типом (в данном случае text/csv)
  base64ToBlob(base64) {
    // Декодируем Base64 строку в байтовую строку
    const byteString = window.atob(base64.data);

    // Устанавливаем MIME тип как text/csv
    const mimeString = 'text/csv';

    // Создаем ArrayBuffer с длиной байтовой строки
    const arrayBuffer = new ArrayBuffer(byteString.length);

    // Создаем Uint8Array для хранения байтов
    const ia = new Uint8Array(arrayBuffer);

    // Заполняем Uint8Array символами из байтовой строки
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Создаем Blob из ArrayBuffer с указанным MIME типом
    const blob = new Blob([arrayBuffer], { type: mimeString });

    return blob;
  },

  // Метод для скачивания отчета в формате CSV
  async downloadP2payReport(data, file) {
    // Преобразуем Base64 строку в Blob
    const blob = this.base64ToBlob(data);

    // Создаем URL объект для Blob
    const objectUrl = window.URL.createObjectURL(blob);

    // Создаем ссылку для скачивания
    const link = document.createElement('a');
    link.href = objectUrl;
    link.setAttribute('download', file);

    // Добавляем ссылку в DOM
    document.body.appendChild(link);

    // Имитируем клик по ссылке для начала скачивания
    link.click();

    // Освобождаем URL объект для Blob
    window.URL.revokeObjectURL(objectUrl);

    // Удаляем ссылку из DOM
    document.body.removeChild(link);
  },

  getFileSize: (length) => {
    // Метод, который получает размер файла в мегабайтах
    if (typeof length !== 'number') return 'unknown';
    if (length < 1024) return `${length} B`;
    if (length < 1048576) return `${(length / 1024).toFixed()} kB`;
    return `${(length / 1048576).toFixed(1)} mB`;
  },

  // Метод который генерирует случайный пароль
  generatePassword: (from = 8, to = 16) => {
    // Вычисляем случайную длину пароля в диапазоне от 'from' до 'to'
    const length = Math.floor(Math.random() * (to - from)) + from;
    let result = '';
    // Набор символов, из которых будет генерироваться пароль
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_';
    const charactersLength = characters.length;

    // Генерируем пароль до тех пор, пока он не пройдет проверку регулярного выражения
    do {
      result = '';
      // eslint-disable-next-line
      for (let i = 0; i < length; i++) {
        // Добавляем случайный символ из набора 'characters' к результату
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
    // Проверяем, соответствует ли сгенерированный пароль регулярному выражению
    } while (!constant.users.PASSWORD_REGEXP_SIGNUP.test(result));

    // Возвращаем сгенерированный пароль
    return result;
  },

  sciToDec,
  getRouteAccessibility,
  getSectionAccessibility,
  getCaption,
  getFullDate,
  isIdentificatorType,
  onlyNumbersHandler,
  getUserTimezone,
};
