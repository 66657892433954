//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import menuList from '~/config/navigationUser';

export default {
  data() {
    return {
      menuList,
    };
  },

  computed: {
    ...mapState('user', ['user']),

    userId() {
      const { id } = this.user;
      if (id) {
        return `Id: ${id}`;
      }
      return '';
    },
  },

  methods: {
    isCurrentRoute(name) {
      const currentRouteName = this.$route.name;
      return (name === currentRouteName);
    },
  },
};
