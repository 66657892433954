export default [




  { title: 'Fees', routeName: 'statistics-fees' },

  { title: 'Trades', routeName: 'statistics-trades' },



];
