import http from '~/http/new';

export default ({
  state: {
    data: [],
  },

  mutations: {
    SET_DATA(state, data) { state.data = data; },
  },

  getters: {
    requestData() {
    //   const {
    //     limit,
    //     page,
    //   } = state;
    //   return {
    //     offset: (page - 1) * limit,
    //     isArbitratorJoined: false,
    //     limit,
    //   };
    },
  },

  actions: {
    // setPage({ commit }, value) { commit('SET_PAGE', value); },
    // setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ commit }) {
      const response = await http.get('v1/p2p/payment-methods/template');
      const { templates } = response.data;
      commit('SET_DATA', templates);
    },

    async addNewTemplate(__store, data) {
      const response = await http.post('v1/p2p/payment-methods/template', data);
      if (!response?.data?.result?.Success) {
        throw new Error(`Unable to add new template because of Error: "${response?.data?.result?.Error}"`);
      }
      return response.data;
    },

    async editTemplate(__store, obj) {
      const response = await http.put(`v1/p2p/payment-methods/template/${obj.id}`, {
        id: obj.id,
        ...obj,
      });
      if (!response?.data?.result?.Success) {
        throw new Error(`Unable to add new template because of Error: "${response?.data?.result?.Error}"`);
      }
      return response.data;
    },

    async deleteTemplate(__store, id) {
      const response = await http.delete(`v1/p2p/payment-methods/template/${id}`);
      if (!response?.data?.Result?.Success) {
        throw new Error(`Unable to delete template because of Error: "${response?.data?.result?.Error}"`);
      }
    },
  },

  namespaced: true,
});
