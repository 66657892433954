import http from '~/http/index';
import constant from '~/const';
import storeUtils from '~/utils/storeUtils';

export default ({
  state: {
    transactionsHistory: [],
    filters: {},
    sort: {
      sort: 'dateCreated',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
    filterLists: {
      List: [
        { id: 0, caption: '' },
      ],
    },
  },

  getters: {
    requestData(state) {
      const { filters, sort, page } = state;
      const itemsPerPage = 100;
      const formattedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});

      const baseData = {
        ...formattedFilters,
        ...sort,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        getTotal: true,
      };

      if (Array.isArray(formattedFilters?.operationTypeList)) {
        const updatedOperationTypeList = formattedFilters.operationTypeList.map((type) => {
          return type === 13 ? 2 : type;
        });

        if (updatedOperationTypeList.includes(2)) {
          baseData.transactionMethodTypeList = [25];
          baseData.operationTypeList = updatedOperationTypeList;
        }
      }

      return baseData;
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_TRANSACTIONS_HISTORY(state, data) {
      const transactionMethodTypeList = constant.operations.TRANSACTION_METHOD_TYPE_LIST;
      state.transactionsHistory = data.map((transaction) => {
        return {
          ...transaction,
          amount: storeUtils.inOutAmount(transaction.amount, transaction.side),
          transactionMethodType: storeUtils.getCaption(
            transactionMethodTypeList, transaction.transactionMethodType,
          ),
          transactionMethodTypeEnum: transaction.transactionMethodType,
        };
      });
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async getTransactionsHistory({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.post('v2/Trading/GetOperations', {
        ...getters.requestData,
        ...data,
      });
      const { items, total } = response.data;
      commit('SET_TRANSACTIONS_HISTORY', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
      return response.data;
    },

    async reloadSingleTransaction({ state, commit }, data) {
      const response = await http.post('v2/Trading/GetOperations', {
        idList: [data.id],
        side: data.side,
        operationTypeList: [data.operationType],
      });
      const { items } = response.data;
      if (items.length === 1) {
        const index = state.transactionsHistory.findIndex((e) => (
          e.id === items[0].id
          && e.side === items[0].side
          && e.operationType === items[0].operationType
        ));
        if (index !== -1) {
          const { transactionsHistory } = state;
          // eslint-disable-next-line
          transactionsHistory[index] = items[0];
          commit('SET_TRANSACTIONS_HISTORY', transactionsHistory);
        }
      }
    },

    async getTransactionMethod(__store, TransactionMethodId) {
      const response = await http.get('Transaction/GetTransactionMethod', {
        params: { TransactionMethodId },
      });
      return response.data;
    },

    async getOperation(__store, data) {
      const response = await http.post('v2/Trading/GetOperation', data);
      return response.data;
    },

    async getTransactionStatusHistory(__store, data) {
      const response = await http.post('Transaction/GetTransactionStatusHistory', data);
      return response.data;
    },

    async adminProcessingTransaction(__store, { transactionId, details }) {
      const response = await http.put('Transaction/AdminProcessingTransaction', { transactionId, details });
      return response.data;
    },

    async adminApproveKyt(__store, { transactionId, details }) {
      const response = await http.put('v2/Transaction/KYTOk', { transactionId, details });
      return response.data;
    },

    async adminRefuseKyt(__store, { transactionId, details }) {
      const response = await http.put('v2/Transaction/KYTReturn', { transactionId, details });
      return response.data;
    },

    async adminConfirmTransaction(__store, { transactionId, details }) {
      const response = await http.put('Transaction/AdminConfirmTransaction', { transactionId, details });
      return response.data;
    },

    async adminCompleteTransaction(__store, { transactionId, details, comment }) {
      const response = await http.put('Transaction/AdminCompleteTransaction', { transactionId, details, comment });
      return response.data;
    },

    async adminCancelTransaction(__store, { transactionId, details }) {
      const response = await http.put('Transaction/AdminCancelTransaction', { transactionId, details });
      return response.data;
    },

    async adminDeclineTransaction(__store, { transactionId, details, comment }) {
      const response = await http.put('Transaction/AdminDeclineTransaction', { transactionId, details, comment });
      return response.data;
    },

    async adminNeedContactTransaction(__store, { transactionId, details }) {
      const response = await http.put('Transaction/AdminNeedContactTransaction', { transactionId, details });
      return response.data;
    },

    async adminCheckTransaction(__store, { transactionId }) {
      const response = await http.put('Transaction/AdminCheckTransaction', { transactionId });
      return response.data;
    },

    async adminCancelCompletedDeposit(__store, { transactionId, details }) {
      const response = await http.put('Transaction/AdminCancelCompletedDeposit', { transactionId, details });
      return response.data;
    },

    async updateTransactionRequisites(__store, data) {
      const response = await http.post('Transaction/UpdateTransactionRequisites', data);
      return response.data;
    },
  },

  namespaced: true,
});
