import http from '~/http/index';
import constant from '~/const';
import { getCaption } from '~/utils/common';

export default ({
  state: {
    data: [],
    filters: {},
    sort: {
      sort: 'dateCreated',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...filters,
        ...sort,
        isArbitrator: true,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        getTotal: true,
      };
    },

    getArbitratorById: (state) => (value) => {
      const { data } = state;
      const arbitrator = data.find((e) => e.id === value);
      return arbitrator;
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data.map((user) => {
        return {
          ...user,
          role: getCaption(constant.p2p.USER_ROLE_LIST, user.role),
        };
      });
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, request) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.get('p2p/Admin/profiles/search', {
        params: {
          ...getters.requestData,
          ...request,
          roles: ['Arbitrator', 'SwapTrader', 'Agent'],
        },
      });
      const { data, total } = response.data;
      if (data !== null) commit('SET_DATA', data);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async changeUserPassword(__store, data) {
      const response = await http.post('User/ChangeUserPassword', data);
      return response.data;
    },

    async grantUser(__store, data) {
      const { userId, roles } = data;
      const response = await http.post(`p2p/Admin/grant/${userId}`, null, {
        params: { roles },
      });
      return response.data;
    },
  },

  namespaced: true,
});
