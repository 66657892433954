import Vue from 'vue';
import Vuex from 'vuex';

import boilerplateTable from '~/store/modules/boilerplateTable';

import common from '~/store/modules/common';
import login from '~/store/modules/login';
import users from '~/store/modules/users';
import user from '~/store/modules/user';
import userId from '~/store/modules/userId';
import userRequisites from '~/store/modules/userRequisites';
import tradersCurrencies from '~/store/modules/tradersCurrencies';
import tradersPaymentMethods from '~/store/modules/tradersPaymentMethods';
import ratePaymentMethods from '~/store/modules/ratePaymentMethods';
import tradersTransactionMethods from '~/store/modules/tradersTransactionMethods';
import userSecurityLockHistory from '~/store/modules/userSecurityLockHistory';
import userSecurityActiveNfa from '~/store/modules/userSecurityActiveNfa';
import userSecurityIpWhiteList from '~/store/modules/userSecurityIpWhiteList';
import userSecurityHistoryNfa from '~/store/modules/userSecurityHistoryNfa';
import userSecurityLog from '~/store/modules/userSecurityLog';
import userSecurity2FaCodes from '~/store/modules/userSecurity2FaCodes';
import userNotifications from '~/store/modules/userNotifications';
import userVerificationRequests from '~/store/modules/userVerificationRequests';
import publicChats from '~/store/modules/publicChats';
import privateChats from '~/store/modules/privateChats';
import userPublicChats from '~/store/modules/userPublicChats';
import userPrivateChats from '~/store/modules/userPrivateChats';
import transactionsStatistics from '~/store/modules/transactionsStatistics';
import currencies from '~/store/modules/currencies';
import transactionsHistory from '~/store/modules/transactionsHistory';
import transactionsHistoryOld from '~/store/modules/transactionsHistoryOld';
import transactionsCodes from '~/store/modules/transactionsCodes';
import tradingOrders from '~/store/modules/tradingOrders';
import tradingTrades from '~/store/modules/tradingTrades';
import userTradingOrders from '~/store/modules/userTradingOrders';
import userTradingTrades from '~/store/modules/userTradingTrades';
import tradingOrderBook from '~/store/modules/tradingOrderBook';
import marketsCurrencyPairs from '~/store/modules/marketsCurrencyPairs';
import addDocuments from '~/store/modules/addDocuments';
import userApiKeys from '~/store/modules/userApiKeys';
import userP2P from '~/store/modules/userP2P';
import userP2PReviews from '~/store/modules/userP2PReviews';
import userP2PRequisites from '~/store/modules/userP2PRequisites';
import userBalanceBalances from '~/store/modules/userBalanceBalances';
import userBalanceTransactions from '~/store/modules/userBalanceTransactions';
import userBalanceTransactionsOld from '~/store/modules/userBalanceTransactionsOld';
import userBalanceLimits from '~/store/modules/userBalanceLimits';
import userReferralIncomes from '~/store/modules/userReferralIncomes';
import userReferralProgram from '~/store/modules/userReferralProgram';
import userReferralReferrals from '~/store/modules/userReferralReferrals';
import userMerchant from '~/store/modules/userMerchant';
import chatOptions from '~/store/modules/chatOptions';
import reports from '~/store/modules/reports';
import accountReports from '~/store/modules/accountReports';
import referralProgramPrograms from '~/store/modules/referralProgramPrograms';
import referralProgramSettings from '~/store/modules/referralProgramSettings';
import referralProgramOwners from '~/store/modules/referralProgramOwners';
import adminManagementLog from '~/store/modules/adminManagementLog';
import adminManagementAdmins from '~/store/modules/adminManagementAdmins';
import accountLog from '~/store/modules/accountLog';
import accountInfo from '~/store/modules/accountInfo';
import settingsNotifications from '~/store/modules/settingsNotifications';
import userApiHistory from '~/store/modules/userApiHistory';
import financeBalancesUserBalances from '~/store/modules/financeBalancesUserBalances';
import settingsLimits from '~/store/modules/settingsLimits';
import settingsEmail from '~/store/modules/settingsEmail';
import userStakingStats from '~/store/modules/userStakingStats';
import userStakingLogs from '~/store/modules/userStakingLogs';
import stakingLogs from '~/store/modules/stakingLogs';
import settingsTechnicalWork from '~/store/modules/settingsTechnicalWork';
import financeWalletsAggregation from '~/store/modules/financeWalletsAggregation';
import statisticsSystemStatusCurrencies from '~/store/modules/statisticsSystemStatusCurrencies';
import statisticsSystemStatusMethods from '~/store/modules/statisticsSystemStatusMethods';
import financeBalancesReports from '~/store/modules/financeBalancesReports';
import stakingCurrencies from '~/store/modules/stakingCurrencies';
import dashboard from '~/store/modules/dashboard';
import p2p from '~/store/modules/p2p';
import p2pOffers from '~/store/modules/p2pOffers';
import p2pDeals from '~/store/modules/p2pDeals';
import p2pModerationWords from '~/store/modules/p2pModerationWords';
import p2pArbitratorsList from '~/store/modules/p2pArbitratorsList';
import p2pBanners from '~/store/modules/p2pBanners';
import paymentMethodsAndCurrenciesCurrencies from '~/store/modules/paymentMethodsAndCurrenciesCurrencies';
import paymentMethodsAndCurrenciesPaymentMethods from '~/store/modules/paymentMethodsAndCurrenciesPaymentMethods';
import settingsIndividual from '~/store/modules/settingsIndividual';
import statisticsGrossProfitExchangeIncome from '~/store/modules/statisticsGrossProfitExchangeIncome';
import statisticsRefProgram from '~/store/modules/statisticsRefProgram';
import statisticsStaking from '~/store/modules/statisticsStaking';
import statisticsTradeTurnover from '~/store/modules/statisticsTradeTurnover';
import statisticsTradeTurnoverTransactions from '~/store/modules/statisticsTradeTurnoverTransactions';
import statisticsUsers from '~/store/modules/statisticsUsers';
import statisticsWdTurnoverWd from '~/store/modules/statisticsWdTurnoverWd';
import statisticsWdTurnoverCodes from '~/store/modules/statisticsWdTurnoverCodes';
import statisticsWdTurnoverMerchant from '~/store/modules/statisticsWdTurnoverMerchant';
import statisticsReferralProgram from '~/store/modules/statisticsReferralProgram';
import paymentsAndCurrenciesPayments from '~/store/modules/paymentsAndCurrenciesPayments';
import paymentsAndCurrenciesPaymentsExtra from '~/store/modules/paymentsAndCurrenciesPaymentsExtra';
import paymentsAndCurrenciesCurrencies from '~/store/modules/paymentsAndCurrenciesCurrencies';
import paymentsAndCurrenciesCurrenciesExtra from '~/store/modules/paymentsAndCurrenciesCurrenciesExtra';
import paymentsAndCurrenciesTokens from '~/store/modules/paymentsAndCurrenciesTokens';
import paymentsAndCurrenciesNetworks from '~/store/modules/paymentsAndCurrenciesNetworks';
import paymentsAndCurrenciesNetworkTokens from '~/store/modules/paymentsAndCurrenciesNetworkTokens';
import settingsSystemNotifications from '~/store/modules/settingsSystemNotifications';
import settingsVerification from '~/store/modules/settingsVerification';
import settingsUploadOptions from '~/store/modules/settingsUploadOptions';
import userBalanceAddresses from '~/store/modules/userBalanceAddresses';
import frontConfigAccesses from '~/store/modules/frontConfigAccesses';
import merchantsStores from '~/store/modules/merchantsStores';
import merchantsProducts from '~/store/modules/merchantsProducts';
import merchantsInvoices from '~/store/modules/merchantsInvoices';
import merchantsInvoicesTransactions from '~/store/modules/merchantsInvoicesTransactions';
import merchantsFees from '~/store/modules/merchantsFees';
import tradersDeals from '~/store/modules/tradersDeals';
import tradersDevices from '~/store/modules/tradersDevices';
import tradersOffers from '~/store/modules/tradersOffers';
import tradersRequisites from '~/store/modules/tradersRequisites';
import tradersFees from '~/store/modules/tradersFees';
import tradersRates from '~/store/modules/tradersRates';
import tradersTransactions from '~/store/modules/tradersTransactions';
import tradersExchange from '~/store/modules/tradersExchange';
import usersGroups from '~/store/modules/usersGroups';
import userDevices from '~/store/modules/userDevices';
import tradersLimits from './modules/tradersLimits';
import settingsWatermark from './modules/settingsWatermark';
import userStatistics from './modules/userStatistics';
import userBalanceAddressesOld from './modules/userBalanceAddressesOld';
import arbitratorCalls from './modules/arbitratorCalls';
import arbitratorDeals from './modules/arbitratorDeals';
import arbitratorCompleted from './modules/arbitratorCompleted';
import arbitratorChat from './modules/arbitratorChat';
import templates from './modules/templates';
import transactionsSettings from './modules/transactionsSettings';
import statisticsFees from './modules/statisticsFees';
import statisticsTrades from './modules/statisticsTrades';
import arbitratorOthersDeals from './modules/arbitratorOthersDeals';
import usersRequests from './modules/usersRequests';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    boilerplateTable,

    common,
    login,
    users,
    user,
    userId,
    usersGroups,
    userRequisites,
    tradersCurrencies,
    tradersPaymentMethods,
    ratePaymentMethods,
    tradersTransactionMethods,
    userSecurityLockHistory,
    userSecurityActiveNfa,
    userSecurityIpWhiteList,
    userSecurityHistoryNfa,
    userSecurityLog,
    userSecurity2FaCodes,
    userNotifications,
    userVerificationRequests,
    publicChats,
    privateChats,
    userPublicChats,
    userPrivateChats,
    transactionsStatistics,
    currencies,
    transactionsHistory,
    transactionsHistoryOld,
    transactionsCodes,
    tradingOrders,
    tradingTrades,
    tradingOrderBook,
    marketsCurrencyPairs,
    addDocuments,
    userApiKeys,
    userTradingOrders,
    userTradingTrades,
    userP2P,
    userP2PReviews,
    userP2PRequisites,
    userBalanceBalances,
    userBalanceTransactions,
    userBalanceLimits,
    userReferralIncomes,
    userReferralProgram,
    userReferralReferrals,
    userMerchant,
    chatOptions,
    reports,
    accountReports,
    referralProgramPrograms,
    referralProgramSettings,
    referralProgramOwners,
    adminManagementLog,
    adminManagementAdmins,
    accountLog,
    accountInfo,
    settingsNotifications,
    userApiHistory,
    financeBalancesUserBalances,
    settingsLimits,
    settingsEmail,
    userStakingStats,
    userStakingLogs,
    stakingLogs,
    settingsTechnicalWork,
    financeWalletsAggregation,
    statisticsSystemStatusCurrencies,
    statisticsSystemStatusMethods,
    financeBalancesReports,
    stakingCurrencies,
    dashboard,
    p2p,
    p2pOffers,
    p2pDeals,
    p2pModerationWords,
    paymentMethodsAndCurrenciesCurrencies,
    paymentMethodsAndCurrenciesPaymentMethods,
    p2pArbitratorsList,
    p2pBanners,
    settingsIndividual,
    statisticsGrossProfitExchangeIncome,
    statisticsRefProgram,
    statisticsStaking,
    statisticsTradeTurnover,
    statisticsTradeTurnoverTransactions,
    statisticsUsers,
    statisticsWdTurnoverWd,
    statisticsWdTurnoverCodes,
    statisticsWdTurnoverMerchant,
    statisticsReferralProgram,
    paymentsAndCurrenciesPayments,
    paymentsAndCurrenciesPaymentsExtra,
    paymentsAndCurrenciesCurrencies,
    paymentsAndCurrenciesCurrenciesExtra,
    paymentsAndCurrenciesTokens,
    paymentsAndCurrenciesNetworks,
    paymentsAndCurrenciesNetworkTokens,
    settingsSystemNotifications,
    settingsVerification,
    settingsUploadOptions,
    userBalanceAddresses,
    frontConfigAccesses,
    merchantsStores,
    merchantsProducts,
    merchantsInvoices,
    merchantsInvoicesTransactions,
    merchantsFees,
    tradersDeals,
    tradersDevices,
    tradersOffers,
    tradersRequisites,
    tradersFees,
    tradersRates,
    tradersTransactions,
    tradersLimits,
    userDevices,
    settingsWatermark,
    tradersExchange,
    userBalanceTransactionsOld,
    userStatistics,
    userBalanceAddressesOld,
    arbitratorCalls,
    arbitratorDeals,
    arbitratorCompleted,
    arbitratorChat,
    templates,
    transactionsSettings,
    statisticsFees,
    statisticsTrades,
    arbitratorOthersDeals,
    usersRequests,
  },
});
