const SOURCES_CODES = {
  Undefined: 0,
  Other: 1,
  Miner: 2,
  Wallet: 3,
  Exchange: 4,
  Marketplace: 5,
  P2P: 6,
  Merchant: 7,
  Payment: 8,
  Bridge: 9,
  SmartContract: 10,
  Lending: 11,
  Liquidity: 12,
  Pharmacy: 13,
  Ico: 14,
  Nft: 15,
  Enforcement: 16,
  Gambling: 17,
  Dark: 18,
  ChildExploitation: 19,
  Illegal: 20,
  Sanctions: 21,
  Scam: 22,
  Stolen: 23,
  Seized: 24,
  Terrorism: 25,
  Fraud: 26,
  Malware: 27,
};

const SOURCES_LIST = [
  {
    id: SOURCES_CODES.Undefined,
    caption: 'Undefined',
  },
  {
    id: SOURCES_CODES.Other,
    caption: 'Other',
  },
  {
    id: SOURCES_CODES.Miner,
    caption: 'Miner',
  },
  {
    id: SOURCES_CODES.Wallet,
    caption: 'Wallet',
  },
  {
    id: SOURCES_CODES.Exchange,
    caption: 'Exchange',
  },
  {
    id: SOURCES_CODES.Marketplace,
    caption: 'Marketplace',
  },
  {
    id: SOURCES_CODES.P2P,
    caption: 'P2P',
  },
  {
    id: SOURCES_CODES.Merchant,
    caption: 'Merchant',
  },
  {
    id: SOURCES_CODES.Payment,
    caption: 'Payment',
  },
  {
    id: SOURCES_CODES.Bridge,
    caption: 'Bridge',
  },
  {
    id: SOURCES_CODES.SmartContract,
    caption: 'Smart contract',
  },
  {
    id: SOURCES_CODES.Lending,
    caption: 'Lending',
  },
  {
    id: SOURCES_CODES.Liquidity,
    caption: 'Liquidity',
  },
  {
    id: SOURCES_CODES.Pharmacy,
    caption: 'Pharmacy',
  },
  {
    id: SOURCES_CODES.Ico,
    caption: 'Ico',
  },
  {
    id: SOURCES_CODES.Nft,
    caption: 'Nft',
  },
  {
    id: SOURCES_CODES.Enforcement,
    caption: 'Enforcement',
  },
  {
    id: SOURCES_CODES.Gambling,
    caption: 'Gambling',
  },
  {
    id: SOURCES_CODES.Dark,
    caption: 'Dark',
  },
  {
    id: SOURCES_CODES.ChildExploitation,
    caption: 'Child exploitation',
  },
  {
    id: SOURCES_CODES.Illegal,
    caption: 'Illegal',
  },
  {
    id: SOURCES_CODES.Sanctions,
    caption: 'Sanctions',
  },
  {
    id: SOURCES_CODES.Scam,
    caption: 'Scam',
  },
  {
    id: SOURCES_CODES.Stolen,
    caption: 'Stolen',
  },
  {
    id: SOURCES_CODES.Seized,
    caption: 'Seized',
  },
  {
    id: SOURCES_CODES.Terrorism,
    caption: 'Terrorism',
  },
  {
    id: SOURCES_CODES.Fraud,
    caption: 'Fraud',
  },
  {
    id: SOURCES_CODES.Malware,
    caption: 'Malware',
  },
];

export default {
  SOURCES_CODES,
  SOURCES_LIST,
};
