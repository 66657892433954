import http from '~/http/index';
import { setToken } from '~/localStorage';

export default ({
  state: {
    currentAdmin: {},
    admins: [],
  },

  getters: {
    getAdminById: (state) => (value) => {
      const { admins } = state;
      const admin = admins.find((e) => e.id === value);
      return admin;
    },

    getCurrentAdmin: (state) => {
      return state.currentAdmin;
    },
  },

  mutations: {
    SET_CURRENT_ADMIN(state, data) {
      state.currentAdmin = data;
    },

    SET_ADMINS(state, data) {
      state.admins = data;
    },
  },

  actions: {
    async signIn({ dispatch }, data) {
      try {
        const response = await http.post('Login/SignIn', data);
        const { accessToken } = response.data;
        setToken(accessToken);
        window.location.replace('/');
      } catch (error) {
        if (
          error.messageCode === 115 // Request is not valid
          || error.messageCode === 126 // Please enter 2FA pin
        ) {
          dispatch('common/setErrorNotification', 'Incorrect email, password or authentication code', { root: true });
        } else {
          throw error;
        }
      }
    },

    async getCurrentAdmin({ commit, dispatch }) {
      const response = await http.post('Login/GetCurrentAdmin');
      const { settings } = response.data;
      commit('SET_CURRENT_ADMIN', response.data);
      dispatch('common/setAdminSettings', settings, { root: true });
    },

    async loadAdmins({ commit }) {
      try {
        const response = await http.get('User/GetAdmins', {
          params: {
            Limit: 1000,
            Offset: 0,
            GetTotal: false,
          },
        });
        const { items } = response.data;
        commit('SET_ADMINS', items);
      } catch (error) {
        commit('SET_ADMINS', []);
      }
    },

    async logout() {
      const response = await http.post('Login/Logout');
      return response.data;
    },

    async checkSignIn() {
      const response = await http.post('Login/CheckSignIn');
      return response;
    },
  },

  namespaced: true,
});
