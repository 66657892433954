import checkConstAvailability from '~/utils/check-const-availability';

const STORE_STATUS = {
  ACTIVE: 0,
  REJECTED: 1,
  AWAITING_APPROVAL: 2,
  INACTIVE: 3,
};

const STORE_STATUS_LIST = [
  {
    id: STORE_STATUS.ACTIVE,
    caption: 'Active',
  },
  {
    id: STORE_STATUS.REJECTED,
    caption: 'Rejected',
  },
  {
    id: STORE_STATUS.AWAITING_APPROVAL,
    caption: 'Awaiting approval',
  },
  {
    id: STORE_STATUS.INACTIVE,
    caption: 'Inactive',
  },
].filter((e) => checkConstAvailability(e, { STORE_STATUS }));

const PRODUCT_STATUS = {
  ACTIVE: 0,
  ARCHIVED: 1,
};

const PRODUCT_STATUS_LIST = [
  {
    id: PRODUCT_STATUS.ACTIVE,
    caption: 'Active',
  },
  {
    id: PRODUCT_STATUS.ARCHIVED,
    caption: 'Archived',
  },
].filter((e) => checkConstAvailability(e, { PRODUCT_STATUS }));

const INVOICE_STATUS = {
  AWAITING_USER_CONFIRMATION: 0,
  CONFIRMED: 1,
  CHECKING: 2,
  DEPOSIT_AWAITING: 3,
  DEPOSIT_RECEIVED: 4,
  DEPOSIT_COMPLETED: 5,
  WITHDRAWAL_PROCESSING: 6,
  WITHDRAWAL_COMPLETED: 7,
  COMPLETED: 8,
  CANCELLED: 9,
  ISSUE: 10,
  ERROR: 11,
  EXPIRED: 12,
  DEPOSIT_CONFIRMED: 13,
  DEPOSIT_ERROR: 14,
  NOT_CREATED: 15,
};

const INVOICE_STATUS_CODES = {
  AwaitingUserConfirmation: 0,
  Confirmed: 1,
  Checking: 2,
  DepositAwaiting: 3,
  DepositReceived: 4,
  DepositCompleted: 5,
  WithdrawalProcessing: 6,
  WithdrawalCompleted: 7,
  Completed: 8,
  Cancelled: 9,
  Issue: 10,
  Error: 11,
  Expired: 12,
  DepositConfirmed: 13,
  DepositError: 14,
  NotCreated: 15,
};

const INVOICE_STATUS_LIST = [
  {
    id: INVOICE_STATUS.AWAITING_USER_CONFIRMATION,
    caption: 'Awaiting user confirmation',
  },
  {
    id: INVOICE_STATUS.CONFIRMED,
    caption: 'Confirmed',
  },
  {
    id: INVOICE_STATUS.CHECKING,
    caption: 'Checking',
  },
  {
    id: INVOICE_STATUS.DEPOSIT_AWAITING,
    caption: 'Deposit awaiting',
  },
  {
    id: INVOICE_STATUS.DEPOSIT_RECEIVED,
    caption: 'Deposit received',
  },
  {
    id: INVOICE_STATUS.DEPOSIT_COMPLETED,
    caption: 'Deposit completed',
  },
  {
    id: INVOICE_STATUS.WITHDRAWAL_PROCESSING,
    caption: 'Withdrawal processing',
  },
  {
    id: INVOICE_STATUS.WITHDRAWAL_COMPLETED,
    caption: 'Withdrawal completed',
  },
  {
    id: INVOICE_STATUS.COMPLETED,
    caption: 'Completed',
  },
  {
    id: INVOICE_STATUS.CANCELLED,
    caption: 'Cancelled',
  },
  {
    id: INVOICE_STATUS.ISSUE,
    caption: 'Issue',
  },
  {
    id: INVOICE_STATUS.ERROR,
    caption: 'Error',
  },
  {
    id: INVOICE_STATUS.EXPIRED,
    caption: 'Expired',
  },
  {
    id: INVOICE_STATUS.DEPOSIT_CONFIRMED,
    caption: 'Deposit confirmed',
  },
  {
    id: INVOICE_STATUS.DEPOSIT_ERROR,
    caption: 'Deposit error',
  },
  {
    id: INVOICE_STATUS.NOT_CREATED,
    caption: 'Not created',
  },
].filter((e) => checkConstAvailability(e, { INVOICE_STATUS }));

const PAYMENT_TRANSACTION_STATUS = {
  NEW: 0,
  PROCESSING: 1,
  VALIDATE: 2,
  COMPLETED: 3,
  ERROR: 4,
};

const PAYMENT_TRANSACTION_STATUS_LIST = [
  {
    id: PAYMENT_TRANSACTION_STATUS.NEW,
    caption: 'New',
  },
  {
    id: PAYMENT_TRANSACTION_STATUS.PROCESSING,
    caption: 'Processing',
  },
  {
    id: PAYMENT_TRANSACTION_STATUS.VALIDATE,
    caption: 'Validate',
  },
  {
    id: PAYMENT_TRANSACTION_STATUS.COMPLETED,
    caption: 'Completed',
  },
  {
    id: PAYMENT_TRANSACTION_STATUS.ERROR,
    caption: 'Error',
  },
].filter((e) => checkConstAvailability(e, { PAYMENT_TRANSACTION_STATUS }));

const PAYMENT_TRANSACTION_TYPE = {
  DEPOSIT: 0,
  WITHDRAWAL: 1,
  RETURN: 2,
  CONVERT: 3,
};

const PAYMENT_TRANSACTION_TYPE_LIST = [
  {
    id: PAYMENT_TRANSACTION_TYPE.DEPOSIT,
    caption: 'Deposit',
  },
  {
    id: PAYMENT_TRANSACTION_TYPE.WITHDRAWAL,
    caption: 'Withdrawal',
  },
  {
    id: PAYMENT_TRANSACTION_TYPE.RETURN,
    caption: 'Return',
  },
  {
    id: PAYMENT_TRANSACTION_TYPE.CONVERT,
    caption: 'Convert',
  },
].filter((e) => checkConstAvailability(e, { PAYMENT_TRANSACTION_STATUS }));

export default {
  STORE_STATUS,
  STORE_STATUS_LIST,
  PRODUCT_STATUS,
  PRODUCT_STATUS_LIST,
  INVOICE_STATUS,
  INVOICE_STATUS_LIST,
  PAYMENT_TRANSACTION_STATUS,
  PAYMENT_TRANSACTION_STATUS_LIST,
  PAYMENT_TRANSACTION_TYPE,
  PAYMENT_TRANSACTION_TYPE_LIST,
  INVOICE_STATUS_CODES,
};
