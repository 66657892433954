import http from '~/http/new';

export default ({
  state: {
    userP2P: {},
  },

  getters: {
    p2pInfoTable: (state) => {
      const {
        averageTradeTime,
        medianTradeTime,
      } = state.userP2P;
      return [
        { key: 'Average trade time:', value: averageTradeTime !== 0 ? `${(averageTradeTime / 60).toFixed(2)} min` : '0' },
        { key: 'Median trade time:', value: medianTradeTime !== 0 ? `${(medianTradeTime / 60).toFixed(2)} min` : '0' },
      ];
    },

    p2pDisputesTable: (state) => {
      const {
        lostDisputesCount,
        wonDisputesCount,
      } = state.userP2P;
      const total = wonDisputesCount + lostDisputesCount;
      return [
        { key: 'Won Disputes:', value: wonDisputesCount !== 0 ? wonDisputesCount : '0' },
        { key: 'Lost Disputes:', value: lostDisputesCount !== 0 ? lostDisputesCount : '0' },
        { key: 'Total number of disputes:', value: total !== 0 ? total : '0' },
        { key: 'Percentage of disputes won:', value: total !== 0 && wonDisputesCount !== 0 ? `${(total / wonDisputesCount) * 100}%` : '0' },
        { key: 'Percentage of disputes lost:', value: total !== 0 && wonDisputesCount !== 0 ? `${(total / lostDisputesCount) * 100}%` : '0' },
      ];
    },
  },

  mutations: {
    SET_USER_P2P_DATA(state, data) {
      state.userP2P = data;
    },
  },

  actions: {
    async loadUserP2PData({ commit }, userId) {
      const response = await http.get('v1/p2p/stats/profile', {
        params: {
          userId,
        },
      });
      const data = Object.keys(response.data).reduce((acc, key) => {
        if (key !== 'result') {
          acc[key] = response.data[key];
        }
        return acc;
      }, {});
      await commit('SET_USER_P2P_DATA', data);
    },
  },

  namespaced: true,
});
