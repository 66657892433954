const ADJECTIVES_LIST = [
  'Admin',
  'Moderator',
  'Member',
  'Guest',
  'Seller',
  'Buyer',
  'VIP',
  'Support',
  'Developer',
  'Tester',
  'Manager',
  'User',
  'Subscriber',
  'Customer',
  'Agent',
  'Broker',
  'Consultant',
  'Assistant',
  'Analyst',
  'Investor',
  'Operator',
  'Trainer',
  'Advisor',
  'Promoter',
  'Contributor',
  'Coordinator',
  'Reviewer',
  'Maintainer',
  'Reporter',
  'Visitor',
  'Collaborator',
  'Creator',
  'Influencer',
  'Marketer',
  'Affiliate',
  'Producer',
  'Sponsor',
  'Guardian',
  'Leader',
  'Follower',
  'Patron',
  'Volunteer',
  'Donor',
  'Advocate',
  'Benefactor',
  'Mentor',
  'Organizer',
  'Planner',
];

export default {
  ADJECTIVES_LIST,
};
