export default [
  { title: 'ID', routeName: 'user-id' },

  { title: 'Security', routeName: 'user-security' },

  { title: 'Balance', routeName: 'user-balance' },




  { title: 'API', routeName: 'user-api' },





  { title: 'Trader', routeName: 'user-trader' },


];
