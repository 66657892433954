import http from '~/http/index';

export default ({
  state: {
    data: [],
    filters: {},
    sort: {
      sort: 'dateCreated',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
    paymentMethods: [],
    filterLists: {
      serviceList: [
        { id: 0, caption: 'Service...' },
      ],
    },
  },

  getters: {
    requestData(state, _getters, rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const { id } = rootState.user.user;
      return {
        profileId: id,
        ...filters,
        ...sort,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        getTotal: true,
      };
    },

    paymentMethodsList(state) {
      const { data } = state;
      return data.map(({ name }) => {
        return name;
      });
    },

    preparedData(state) {
      const { data, paymentMethods } = state;
      const filteredData = data.filter((item) => {
        return paymentMethods.includes(item.paymentMethodName);
      });

      return paymentMethods.length > 0 ? filteredData : data;
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_PAYMENT_METHODS_FILTER(state, data) {
      state.paymentMethods = data;
    },

    SET_DATA(state, data) {
      state.data = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setPaymentMethodsFilter({ commit }, data) { commit('SET_PAYMENT_METHODS_FILTER', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, request) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.post('p2p/Admin/requisites/search', {
        ...getters.requestData,
        ...request,
      });
      const { data, total } = response.data;
      commit('SET_DATA', data);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },
  },

  namespaced: true,
});
