import http from '~/http/index';
import newhttp from '~/http/new';

export default ({
  state: {
    data: [],
    additionalData: {},
    filters: {},
    sort: {
      sort: 'availableFunds',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state, getters, rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const { id } = rootState.user.user;
      const formattedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});

      const baseData = {
        ...formattedFilters,
        userId: id,
      };

      if (getters.appType) {
        return {
          ...baseData,
        };
      }

      return {
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        getTotal: true,
        ...sort,
        ...baseData,
      };
    },

    appType() {
      return process.env.VUE_APP_EXCLUDE_FEATURE?.split(' ').includes('transactions-old');
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_ADDITIONAL_DATA(state, data) {
      state.additionalData = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];

      const url = getters.appType ? 'v1/balances' : 'v2/Trading/GetBalanceInfo';
      const protocol = getters.appType ? newhttp : http;
      const method = getters.appType ? 'get' : 'post';

      const requestData = method === 'get'
        ? { params: { ...getters.requestData, ...data } }
        : { ...getters.requestData, ...data };

      const response = await protocol[method](url, requestData);

      const {
        items,
        total,
        userId,
        cumulativeCurrencyId,
        cumulativeCurrencyTitle,
      } = response.data;

      commit('SET_DATA', items);
      commit('SET_ADDITIONAL_DATA', { userId, cumulativeCurrencyId, cumulativeCurrencyTitle });
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async addBalance(__store, data) {
      if (!process.env.VUE_APP_EXCLUDE_FEATURE.includes('transactions-old')) {
        const response = await http.post('Transaction/AdminDeposit', data);
        return response;
      }

      const response = await newhttp.post('v1/transactions/deposit', data);
      return response;
    },

    async withdrawalBalance(__store, data) {
      try {
        if (!process.env.VUE_APP_EXCLUDE_FEATURE.includes('transactions-old')) {
          const response = await http.post('Transaction/AdminWithdrawal', data);
          if (!response?.data?.result?.Success) {
            throw new Error(`Failed to withdraw from balance due to: "${response?.data?.result?.Error}"`);
          }
          return response;
        }
        const response = await newhttp.post('v1/transactions/withdrawal', data);
        if (!response?.data?.result?.Success) {
          throw new Error(`Failed to withdraw from balance due to: "${response?.data?.result?.Error}"`);
        }
        return response;
      } catch (error) {
        throw new Error(error.message);
      }
    },
  },

  namespaced: true,
});
