import i18n from '@/plugins/i18n';
import http from '~/http/index';

export default ({
  state: {},

  getters: {},

  mutations: {},

  actions: {
    async changePassword(__store, data) {
      const response = await http.post('v2/Login/ChangePassword', data);
      if (response.error) {
        switch (response.error.message) {
          case 'Invalid email or password':
            throw new Error(i18n.t('notification.Invalid email or password'));
          default:
            throw new Error(i18n.t('notification.Unexpected error'));
        }
      }
      return response.data;
    },
  },

  namespaced: true,
});
