import checkConstAvailability from '~/utils/check-const-availability';

const EXCHANGE_URL = process.env.VUE_APP_EXCHANGE_URL;

const TRADE_STATUS = {
  CREATED: 'Created',
  MFA_SENT: 'MFASent',
  FUNDS_FREEZE_CONFIRMATION: 'FundsFreezeConfirmation',
  FUNDS_FROZEN: 'FundsFrozen',
  FIAT_SENT: 'FiatSent',
  REFUSED: 'Refused',
  COMPLETED: 'Completed',
  CANCELLED: 'Cancelled',
  TERMINATED: 'Terminated',
  TIMEOUT_ARBITRATOR: 'TimeoutArbitrator',
  ARBITRATOR_REQUIRED: 'ArbitratorRequired',
  ARBITRATOR_ENTERED: 'ArbitratorEntered',
};

const TRADE_STATUS_LIST = [
  {
    id: TRADE_STATUS.CREATED,
    caption: 'Created',
  },
  {
    id: TRADE_STATUS.MFA_SENT,
    caption: 'MFA sent',
  },
  {
    id: TRADE_STATUS.FUNDS_FREEZE_CONFIRMATION,
    caption: 'Funds freeze confirmation',
  },
  {
    id: TRADE_STATUS.FUNDS_FROZEN,
    caption: 'Funds frozen',
  },
  {
    id: TRADE_STATUS.FIAT_SENT,
    caption: 'Fiat sent',
  },
  {
    id: TRADE_STATUS.REFUSED,
    caption: 'Refused',
  },
  {
    id: TRADE_STATUS.COMPLETED,
    caption: 'Completed',
  },
  {
    id: TRADE_STATUS.CANCELLED,
    caption: 'Cancelled',
  },
  {
    id: TRADE_STATUS.TERMINATED,
    caption: 'Terminated',
  },
  {
    id: TRADE_STATUS.TIMEOUT_ARBITRATOR,
    caption: 'Timeout arbitrator',
  },
  {
    id: TRADE_STATUS.ARBITRATOR_REQUIRED,
    caption: 'Arbitrator required',
  },
  {
    id: TRADE_STATUS.ARBITRATOR_ENTERED,
    caption: 'Arbitrator entered',
  },
].filter((e) => checkConstAvailability(e, { TRADE_STATUS }));

const OFFER_TYPE = {
  SELL: 'Sell',
  BUY: 'Buy',
};

const OFFER_TYPE_LIST = [
  {
    id: OFFER_TYPE.SELL,
    caption: 'Sell',
  },
  {
    id: OFFER_TYPE.BUY,
    caption: 'Buy',
  },
];

const OFFER_STATUS = {
  HIDDEN: 'isHidden',
  ENABLED: 'isEnabled',
  DELETED: 'isDeleted',
  BLOCKED: 'isBlocked',
};

const OFFER_STATUS_LIST = [
  {
    id: OFFER_STATUS.ENABLED,
    caption: 'Enabled',
  },
  {
    id: OFFER_STATUS.DELETED,
    caption: 'Deleted',
  },
  {
    id: OFFER_STATUS.HIDDEN,
    caption: 'Hidden',
  },
  {
    id: OFFER_STATUS.BLOCKED,
    caption: 'Blocked',
  },
].filter((e) => checkConstAvailability(e, { OFFER_STATUS }));

const CURRENCY_TYPE = {
  CRYPTO: 'Cryptocurrency',
  FIAT: 'Fiat',
};

const USER_ROLE = {
  USER: 1,
  ARBITRATOR: 2,
  SWAPTRADER: 7,
  AGENT: 16,
};

const USER_ROLE_LIST = [
  {
    id: USER_ROLE.ARBITRATOR,
    caption: 'Arbitrator',
  },
  {
    id: USER_ROLE.SWAPTRADER,
    caption: 'Swap Trader',
  },
  {
    id: USER_ROLE.AGENT,
    caption: 'Agent',
  },
].filter((e) => checkConstAvailability(e, { USER_ROLE }));

const PAYMENT_METHOD_CATEGORY = {
  NONE: 0,
  CASH: 1,
  BANK_TRANSFER: 2,
  CRYPTO_CURRENCIES: 3,
  E_WALLETS: 4,
  PREPAID_CARD: 5,
};

const PAYMENT_METHOD_CATEGORY_LIST = [
  {
    id: PAYMENT_METHOD_CATEGORY.CASH,
    caption: 'Cash',
  },
  {
    id: PAYMENT_METHOD_CATEGORY.BANK_TRANSFER,
    caption: 'Bank transfer',
  },
  {
    id: PAYMENT_METHOD_CATEGORY.CRYPTO_CURRENCIES,
    caption: 'Cryptocurrencies',
  },
  {
    id: PAYMENT_METHOD_CATEGORY.E_WALLETS,
    caption: 'EWallets',
  },
  {
    id: PAYMENT_METHOD_CATEGORY.PREPAID_CARD,
    caption: 'Prepaid cards',
  },
  {
    id: PAYMENT_METHOD_CATEGORY.NONE,
    caption: 'None',
  },
].filter((e) => checkConstAvailability(e, { PAYMENT_METHOD_CATEGORY }));

const PAYMENT_METHOD_TYPE = {
  ONLINE: 1,
  OFFLINE: 2,
};

const PAYMENT_METHOD_TYPE_LIST = [
  {
    id: PAYMENT_METHOD_TYPE.ONLINE,
    caption: 'Online',
  },
  {
    id: PAYMENT_METHOD_TYPE.OFFLINE,
    caption: 'Offline',
  },
].filter((e) => checkConstAvailability(e, { PAYMENT_METHOD_TYPE }));

const FEEDBACK_INFLUENCE = {
  NEGATIVE: 'Negative',
  NEUTRAL: 'Neutral',
  POSITIVE: 'Positive',
};

const FEEDBACK_INFLUENCE_LIST = [
  {
    id: FEEDBACK_INFLUENCE.NEGATIVE,
    caption: 'Negative',
  },
  {
    id: FEEDBACK_INFLUENCE.NEUTRAL,
    caption: 'Neutral',
  },
  {
    id: FEEDBACK_INFLUENCE.POSITIVE,
    caption: 'Positive',
  },
].filter((e) => checkConstAvailability(e, { FEEDBACK_INFLUENCE }));

const ICON_TYPE = {
  CURRENCY: 0,
  METHOD: 1,
};

const INITIATOR_TYPE = {
  SYSTEM: 0,
  USER: 1,
  BUYER: 2,
  SELLER: 3,
};

const INITIATOR_TYPE_LIST = [
  {
    id: INITIATOR_TYPE.SYSTEM,
    caption: 'System',
  },
  {
    id: INITIATOR_TYPE.USER,
    caption: 'User',
  },
  {
    id: INITIATOR_TYPE.BUYER,
    caption: 'Buyer',
  },
  {
    id: INITIATOR_TYPE.SELLER,
    caption: 'Seller',
  },
].filter((e) => checkConstAvailability(e, { INITIATOR_TYPE }));

const CHAT_MEMBER_TYPE = {
  CUSTOMER: 0,
  SYSTEM: 1,
  ADMIN: 2,
  ARBITRATOR: 3,
};

const CHAT_MEMBER_TYPE_LIST = [
  {
    id: CHAT_MEMBER_TYPE.CUSTOMER,
    caption: 'Customer',
  },
  {
    id: CHAT_MEMBER_TYPE.SYSTEM,
    caption: 'System',
  },
  {
    id: CHAT_MEMBER_TYPE.ADMIN,
    caption: 'Admin',
  },
  {
    id: CHAT_MEMBER_TYPE.ARBITRATOR,
    caption: 'Arbitrator',
  },
].filter((e) => checkConstAvailability(e, { CHAT_MEMBER_TYPE }));


const PAYMENT_FIELD_TYPE = {
  BOOLEAN: 0,
  INTEGER: 1,
  TEXT: 2,
  TEXT_MULTILINE: 3,
};

const PAYMENT_FIELD_LIST = [
  {
    id: PAYMENT_FIELD_TYPE.BOOLEAN,
    caption: 'Boolean',
  },
  {
    id: PAYMENT_FIELD_TYPE.INTEGER,
    caption: 'Integer/Numbers only',
  },
  {
    id: PAYMENT_FIELD_TYPE.TEXT,
    caption: 'Text',
  },
  {
    id: PAYMENT_FIELD_TYPE.TEXT_MULTILINE,
    caption: 'Multiline Text',
  },
].filter((e) => checkConstAvailability(e, { PAYMENT_FIELD_TYPE }));

export default {
  TRADE_STATUS,
  TRADE_STATUS_LIST,
  OFFER_TYPE,
  OFFER_STATUS_LIST,
  OFFER_TYPE_LIST,
  CURRENCY_TYPE,
  USER_ROLE,
  USER_ROLE_LIST,
  PAYMENT_METHOD_CATEGORY,
  PAYMENT_METHOD_CATEGORY_LIST,
  PAYMENT_METHOD_TYPE,
  PAYMENT_METHOD_TYPE_LIST,
  FEEDBACK_INFLUENCE,
  FEEDBACK_INFLUENCE_LIST,
  ICON_TYPE,
  INITIATOR_TYPE,
  INITIATOR_TYPE_LIST,
  CHAT_MEMBER_TYPE,
  CHAT_MEMBER_TYPE_LIST,
  PAYMENT_FIELD_TYPE,
  PAYMENT_FIELD_LIST,
  EXCHANGE_URL,
};
