import i18n from '~/plugins/i18n';
import http from '~/http/new';

export default ({
  state: {
    groups: [],
  },

  getters: {
    usersGroups(state) {
      const { groups } = state;

      const userGroups = groups.reduce((usersAcc, group) => {
        const newAcc = { ...usersAcc };
        group.profiles.forEach((profile) => {
          if (profile.userId) {
            if (!usersAcc[profile.userId]) {
              newAcc[profile.userId] = [];
            }
            newAcc[profile.userId].push(group.id);
          }
        });
        return newAcc;
      }, {});

      return userGroups;
    },
  },

  mutations: {
    SET_GROUPS(state, data) {
      state.groups = data;
    },
  },

  actions: {
    async getGroups({ commit }) {
      const response = await http.get('v1/p2p/groups');
      const { groups } = response.data;
      commit('SET_GROUPS', groups);
    },

    async statsProfileById(__state, { userId }) {
      const { data } = await http.get('v1/p2p/stats/profile', { params: { userId } });
      if (!data.result?.Success && data.result?.Error) {
        throw new Error(data.result.Error);
      }
    },

    async addGroup(__state, payload) {
      const { data } = await http.post('v1/p2p/groups', payload);
      if (!data.result?.Success && data.result?.Error) {
        throw new Error(data.result.Error);
      } else {
        return data;
      }
    },

    async removeGroup(__state, { id }) {
      const { data } = await http.delete(`v1/p2p/groups/${id}`);
      if (!data.result?.Success && data.result?.Error) {
        throw new Error(data.result.Error);
      }
    },

    async addUsersToGroup(__state, payload) {
      const { data } = await http.post(`v1/p2p/groups/${payload.id}/profiles`, payload);
      if (!data.result?.Success && data.result?.Error) {
        throw new Error(data.result.Error);
      }
    },

    async removeUsersFromGroup(__state, payload) {
      const { data } = await http.delete(`v1/p2p/groups/${payload.id}/profiles`, { params: payload });
      if (!data.result?.Success && data.result?.Error) {
        switch (data.result.Error) {
          case 'ProfileWithoutGroup':
            throw new Error(i18n.t('errors.ProfileWithoutGroup'));
          default:
            throw new Error(i18n.t('Unexpected error'));
        }
      }
    },
  },

  namespaced: true,
});
