import http from '~/http/index';

export default ({
  state: {
    data: [],
    additionalData: {},
    filters: {},
    sort: {
      sort: 'dateCreated',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
    filterLists: {
      activeList: [
        { id: true, caption: 'Active' },
        { id: false, caption: 'Disabled' },
      ],
    },
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      // Используем деструктуризацию для улучшения читаемости кода
      const { searchString } = filters;
      // Если searchString начинается с "r=", обрезаем префикс, иначе оставляем без изменений
      const processedSearchString = searchString?.startsWith('r=') ? searchString.slice(2) : searchString;
      return {
        ...filters,
        ...sort,
        searchString: processedSearchString,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        getTotal: true,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_ADDITIONAL_DATA(state, data) {
      state.additionalData = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({
      getters,
      commit,
      rootGetters,
    }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.post('v2/Referral/GetReferralPrograms', {
        ...getters.requestData,
        ...data,
      });
      const { items, total, cumulativeCurrencyTitle } = response.data;
      commit('SET_DATA', items);
      commit('SET_ADDITIONAL_DATA', { cumulativeCurrencyTitle });
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async updateReferralProgram(__store, data) {
      await http.post('v2/Referral/UpdateReferralProgram', data);
    },

    async addUserToReferral(__store, data) {
      await http.put('v2/Referral/AddUserToReferral', data);
    },

    async getReferralsByParams(__store, data) {
      const response = await http.post('v2/Referral/GetReferrals', data);
      return response.data;
    },
  },

  namespaced: true,
});
