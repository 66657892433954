import i18n from '@/plugins/i18n';
import http from '~/http/index';
import newhttp from '~/http/new';

export default ({
  state: {
    data: [],
    filters: {},
    sort: {
      sort: 'dateCreated',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state, getters, rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const { id } = rootState.login.currentAdmin;
      const baseData = {
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        ...filters,
      };

      if (getters.appType) {
        return {
          adminId: [String(id)],
          ...baseData,
        };
      }

      return {
        adminIdList: [id],
        getTotal: true,
        ...sort,
        ...baseData,
      };
    },
    appType() {
      return process.env.VUE_APP_EXCLUDE_FEATURE?.split(' ').includes('transactions-old');
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];

      const url = getters.appType ? 'v1/reports' : 'v2/Report/GetReports';
      const protocol = getters.appType ? newhttp : http;
      const method = getters.appType ? 'get' : 'post';

      const requestData = method === 'get'
        ? { params: { ...getters.requestData, ...data } }
        : { ...getters.requestData, ...data };

      const response = await protocol[method](url, requestData);

      const { items, total } = response.data;
      commit('SET_DATA', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async getReport({ getters }, data) {
      const url = getters.appType ? `v1/reports/${data.reportId}` : 'v2/Report/GetReport';
      const protocol = getters.appType ? newhttp : http;
      const method = getters.appType ? 'get' : 'post';

      const requestData = method === 'get'
        ? { params: { id: data.reportId } }
        : { ...data };

      const response = await protocol[method](url, requestData);

      if (typeof response.data !== 'string') {
        switch (response?.data?.result?.Error) {
          case 'Status(StatusCode="ResourceExhausted", Detail="Received message exceeds the maximum configured message size.")':
            throw new Error(i18n.t('notification.Received message exceeds the maximum configured message size'));
          case 'FileNotFound':
            throw new Error(i18n.t('errors.FileNotFound'));
          default:
            throw new Error(i18n.t('Unexpected error'));
        }
      } else {
        return response;
      }
    },
  },

  namespaced: true,
});
