/* eslint max-len: "off" */
import login from '@/store/modules/login';

import Vue from 'vue';
import VueRouter from 'vue-router';
import home from '~/views/Home.vue';

import userRoutes from '~/router/user';
import usersRoutes from '~/router/users';
import paymentsAndCurrenciesRoutes from '~/router/paymentsAndCurrencies';
import settingsRoutes from '~/router/settings';
import financeRoutes from '~/router/finance';
import p2pRoutes from '~/router/p2p';
import tradersRoutes from '~/router/traders';
import statisticsRoutes from '~/router/statistics';
import transactionsRoutes from '~/router/transactions';
import merchantsRoutes from '~/router/merchants';
import arbitratorRoutes from '~/router/arbitrator';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      header: { type: 'text', value: 'Admin panel' },
    },
    children: [
      // {
      //   path: '/boilerplate',
      //   name: 'boilerplate',
      //   component: () => import(/* webpackChunkName: "frontConfig" */ '~/views/Boilerplate/Boilerplate.vue'),
      //   meta: {
      //     header: { type: 'text', value: 'Boilerplate' },
      //   },
      // },



      {
        path: '/users',
        component: () => import(/* webpackChunkName: "users" */ '~/views/Users/Users.vue'),
        children: usersRoutes,
      },

      {
        path: '/user/:id',
        component: () => import(/* webpackChunkName: "user" */ '~/views/User/User.vue'),
        children: userRoutes,
      },


      {
        path: '/transactions',
        // name: 'transactions',
        component: () => import(/* webpackChunkName: "transactions" */ '~/views/Transactions/Transactions.vue'),
        children: transactionsRoutes,
        // meta: {
        //   header: { type: 'text', value: 'Transactions' },
        // },
      },




      {
        path: '/account',
        name: 'account',
        component: () => import(/* webpackChunkName: "account" */ '~/views/Account/Account.vue'),
        meta: {
          header: { type: 'text', value: 'Account' },
        },
      },


      {
        path: '/admin-management',
        name: 'adminManagement',
        component: () => import(/* webpackChunkName: "adminManagement" */ '~/views/AdminManagement/AdminManagement.vue'),
        meta: {
          header: { type: 'text', value: 'Admin management' },
        },
      },

      {
        path: '/settings',
        component: () => import(/* webpackChunkName: "settings" */ '~/views/Settings/Settings.vue'),
        children: settingsRoutes,
      },


      {
        path: '/arbitrator',
        component: () => import(/* webpackChunkName: "arbitrator" */ '~/views/Arbitrator/Arbitrator.vue'),
        children: arbitratorRoutes,
        meta: {
          requiredArbitrator: true,
        },
      },




      {
        path: '/traders',
        component: () => import(/* webpackChunkName: "traders" */ '~/views/Traders/Traders.vue'),
        children: tradersRoutes,
      },

      {
        path: '/statistics',
        component: () => import(/* webpackChunkName: "statistics" */ '~/views/Statistics/Statistics.vue'),
        children: statisticsRoutes,
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '~/views/Login.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "notFound" */ '~/views/NotFound.vue'),
    meta: {
      hideNavigation: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredArbitrator)) {
    // await login.actions.getCurrentAdmin();
    if (login.state.currentAdmin.adminStatus <= 3) {
      next();
    } else {
      next();
      // next({
      //   path: '/users',
      // });
      // next(false);
    }
  } else {
    next();
  }
});

export default router;
