import moment from 'moment';
import newhttp from '~/http/new';

export default ({
  state: {
    data: [],
    filters: {},
    // sort: {
    //   sort: 'totalDeposit',
    //   sortDesc: true,
    // },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state) {
      const { filters } = state;
      // const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...filters,
        createdFrom: filters.createdFrom ? moment.utc(filters.createdFrom).unix() : undefined,
        createdTo: filters.createdTo ? moment.utc(filters.createdTo).set({ hour: 23, minute: 59, second: 59 }).unix() : undefined,
        // ...sort,
        // offset: (page - 1) * itemsPerPage,
        // limit: itemsPerPage,
      };
    },

    reportData(state) {
      const { filters } = state;
      return {
        ...filters,
        createdFrom: filters.createdFrom ? String(moment.utc(filters.createdFrom).unix()) : undefined,
        createdTo: filters.createdTo ? String(moment.utc(filters.createdTo).set({ hour: 23, minute: 59, second: 59 }).unix()) : undefined,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await newhttp.get('v1/statistic', {
        params: {
          ...getters.requestData,
          ...data,
        },
      });
      const { items, total } = response.data;
      commit('SET_DATA', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },
  },

  namespaced: true,
});
