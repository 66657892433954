import http from '~/http/index';

export default ({
  state: {
    data: [],
    filters: {},
    sort: {
      sort: 'createdAt',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...filters,
        ...sort,
        page: page - 1,
        limit: itemsPerPage,
        getTotal: true,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },
    SET_USER_REVIEWS_DATA(state, data) {
      state.data = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },
    async loadData({ commit, getters }, params) {
      const response = await http.get('p2p/Admin/feedback', {
        params: {
          ...getters.requestData,
          ...params,
        },
      });
      const { data } = response.data;
      await commit('SET_USER_REVIEWS_DATA', data);
    },

    async getFeedbackHistory({ getters }, params) {
      const response = await http.get(`p2p/Admin/feedback/${params.feedbackId}/history`, {
        params: {
          ...getters.requestData,
          ...params,
        },
      });
      const { data } = response.data;
      return data;
    },

    async deleteFeedback(__store, feedbackId) {
      const response = await http.delete(`p2p/Admin/feedback/${feedbackId}`);
      return response.data;
    },

    async editFeedback(__store, { feedbackId, comment, influence }) {
      const response = await http.put(`p2p/Admin/feedback/${feedbackId}`, {
        comment,
        influence,
      });
      return response.data;
    },
  },

  namespaced: true,
});
