export default [
  {
    path: '',
    name: 'user-id',
    component: () => import(/* webpackChunkName: "user-id" */ '~/views/UserId/UserId.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUser' },
      rootRouteName: 'users',
    },
  },

  {
    path: 'security',
    name: 'user-security',
    component: () => import(/* webpackChunkName: "user-security" */ '~/views/UserSecurity/UserSecurity.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUser' },
      rootRouteName: 'users',
    },
  },


  {
    path: 'api',
    name: 'user-api',
    component: () => import(/* webpackChunkName: "user-api" */ '~/views/UserApi/UserApi.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUser' },
      rootRouteName: 'users',
    },
  },




  {
    path: 'balance',
    name: 'user-balance',
    component: () => import(/* webpackChunkName: "user-balance" */ '~/views/UserBalance/UserBalance.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUser' },
      rootRouteName: 'users',
    },
  },




  {
    path: 'trader',
    name: 'user-trader',
    component: () => import(/* webpackChunkName: "user-trader" */ '~/views/UserTrader/UserTrader.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUser' },
      rootRouteName: 'users',
    },
  },


];
