import http from '~/http/index';

export default ({
  state: {
    chartData: [],
    filters: {},
    sort: {
      sort: 'defaultSort',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state) {
      const { filters } = state;
      return {
        ...filters,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.chartData = data;
      // state.amounts = data.map((item) => {
      //   return {
      //     ...item,
      //     id: item.currencyId,
      //   };
      // });
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ commit, getters }, data) {
      const response = await http.post('/v2/Finance/GetTradingStats', {
        ...getters.requestData,
        ...data,
      });
      const { items } = response.data;
      commit('SET_DATA', items);
    },
  },

  namespaced: true,
});
