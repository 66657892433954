import http from '~/http/index';

export default ({
  state: {
    user: {},
  },

  getters: {
    userSecurity(state) {
      const { nfaList } = state.user;
      const mfaIdsList = [0, 1, 3];
      const findMFA = nfaList.filter((data) => {
        const isActuallyEnabled = new Date(data.dateDisabled) < new Date(data.dateEnabled);
        return isActuallyEnabled && mfaIdsList.includes(data.serviceType);
      });
      return nfaList.map((nfa) => {
        return {
          ...nfa,
          isDisabledRemove: mfaIdsList.includes(nfa.serviceType) && findMFA.length === 1,
        };
      });
    },
  },

  mutations: {
    SET_USER(state, data) {
      state.user = data;
    },
  },

  actions: {
    async loadUser({ commit }, value) {
      await commit('SET_USER', {});
      const { data } = await http.post('v2/User/GetUser', {
        userId: value,
      });
      await commit('SET_USER', data);
    },

    async silentLoadUser({ commit }, value) {
      const { data } = await http.post('v2/User/GetUser', {
        userId: value,
      });
      await commit('SET_USER', data);
    },

    async getUser(__store, userId) {
      const response = await http.post('v2/User/GetUser', { userId });
      return response.data;
    },

    async getUsername(__store, userId) {
      const response = await http.post('v2/User/GetUser', { userId });
      return response.data;
    },

    async changeUserPassword(__store, data) {
      const response = await http.post('User/ChangeUserPassword', data);
      return response.data;
    },
  },

  namespaced: true,
});
