//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

export default {
  props: {
    filters: { type: Object, default: () => ({}) },
    name: { type: String, required: true },
    isUnchanged: { type: Boolean, default: true },
  },

  data() {
    return {
      modals: {
        saveFilter: {
          show: false,
          processing: false,
          valid: false,
          name: '',
          nameRules: [
            (v) => !this.savedFiltersList.some((e) => e.name === v) || this.$t('common.A set with the same name already exists'),
            (v) => !!v || this.$t('common.Name is required'),
          ],
        },
        removeFilter: {
          show: false,
          processing: false,
          name: '',
        },
      },
    };
  },

  computed: {
    ...mapState('common', ['adminSettings', 'generalLoader']),

    savedFiltersList() {
      const { savedFilters } = this.adminSettings;
      const { name } = this;
      const current = savedFilters[name];
      if (current) {
        return current;
      }
      return [];
    },

    hasSavedFilters() {
      const { length } = this.savedFiltersList;
      return length;
    },
  },

  methods: {
    ...mapActions('common', ['setSavedFilters', 'setSuccessNotification']),

    async saveFilter() {
      this.modals.saveFilter.show = true;
      this.modals.saveFilter.name = '';
      await this.$nextTick();
      this.$refs.form.resetValidation();
    },

    async applySaveFilter() {
      const { filters } = this;
      const data = {
        key: this.name,
        value: [
          ...this.savedFiltersList,
          {
            name: this.modals.saveFilter.name,
            data: { ...filters },
          },
        ],
      };
      this.modals.saveFilter.processing = true;
      await this.setSavedFilters(data);
      this.modals.saveFilter.processing = false;
      this.modals.saveFilter.show = false;
      this.setSuccessNotification('Current filters saved');
    },

    applyFilter(filter) {
      const { data } = filter;
      this.$emit('apply', data);
    },

    removeFilter(name) {
      this.modals.removeFilter.show = true;
      this.modals.removeFilter.name = name;
    },

    async applyRemoveFilter() {
      const { name } = this.modals.removeFilter;
      const { savedFiltersList } = this;
      const filteredList = savedFiltersList.filter((e) => e.name !== name);
      const data = {
        key: this.name,
        value: filteredList,
      };
      this.modals.removeFilter.processing = true;
      await this.setSavedFilters(data);
      this.modals.removeFilter.processing = false;
      this.modals.removeFilter.show = false;
      this.setSuccessNotification('Filter settings successfully removed');
    },

    resetFilters() {
      this.$emit('reset');
    },
  },
};
