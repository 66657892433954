import http from '~/http/index';
import $utils from '~/utils/storeUtils';


export default ({
  state: {
    userP2P: {},
  },

  getters: {
    p2pInfoTable: (state) => {
      const {
        completedTrades,
        averageTradeTime,
        statistics,
        monthlyVolume,
        allVolume,
      } = state.userP2P;
      return [
        { key: 'Completed Trades:', value: completedTrades.toString() },
        { key: 'Counterparties:', value: statistics.trades.totalClients.toString() },
        { key: 'Average trade time:', value: `${averageTradeTime !== -1 ? (averageTradeTime / 60).toFixed(2) : 0}` },
        { key: 'Median trade time:', value: `${statistics.trades.medianTradesTime !== -1 ? (statistics.trades.medianTradesTime / 60).toFixed(2) : 0}` },
        { key: 'Turnover per month:', value: `${monthlyVolume} USD` },
        { key: 'Turnover for all time:', value: `${allVolume} USD` },
      ];
    },

    p2pActivityTable: (state) => {
      const {
        firstTradeDate,
        lastTradeDate,
      } = state.userP2P.statistics.trades;
      return [
        { key: 'First deal:', value: firstTradeDate !== null ? $utils.getFullDate(firstTradeDate) : '–' },
        { key: 'Last deal:', value: lastTradeDate !== null ? $utils.getFullDate(lastTradeDate) : '–' },
      ];
    },

    p2pRatingTable: (state) => {
      const {
        rating,
        statistics: {
          clients,
        },
      } = state.userP2P;
      return [
        { key: 'Positive reviews:', value: `${rating * 100}%` },
        { key: 'Trusted:', value: clients.trusted.toString() },
        { key: 'Blocked:', value: clients.blocked.toString() },
      ];
    },
  },

  mutations: {
    SET_USER_P2P_DATA(state, data) {
      state.userP2P = data;
    },
  },

  actions: {
    async loadUserP2PData({ commit }, params) {
      const response = await http.get('p2p/Admin/profile', {
        params: { ...params },
      });
      await commit('SET_USER_P2P_DATA', response.data);
    },
  },

  namespaced: true,
});
