import checkConstAvailability from '~/utils/check-const-availability';

const LANGUAGE = {
  EN: 'En',
  RU: 'Ru',
  ZH: 'Zh',
  ES: 'Es',
  ET: 'Et',
};

const LANGUAGE_LIST = [
  {
    id: LANGUAGE.EN,
    caption: 'English',
  },
  {
    id: LANGUAGE.RU,
    caption: 'Russian',
  },
  {
    id: LANGUAGE.ZH,
    caption: 'Chinese',
  },
  {
    id: LANGUAGE.ES,
    caption: 'Spanish',
  },
  {
    id: LANGUAGE.ET,
    caption: 'Estonian',
  },
].filter((e) => checkConstAvailability(e, { LANGUAGE }));

const EMAIL_BODY_TYPE = {
  HTML: 0,
  PLAIN_TEXT: 1,
};

const EMAIL_BODY_TYPE_LIST = [
  {
    id: EMAIL_BODY_TYPE.HTML,
    caption: 'HTML',
  },
  {
    id: EMAIL_BODY_TYPE.PLAIN_TEXT,
    caption: 'Plain text',
  },
].filter((e) => checkConstAvailability(e, { EMAIL_BODY_TYPE }));

const NOTIFICATION_TYPE = {
  SIGN_IN: 0,
  SIGN_UP: 1,
  UNKNOWN_IP: 2,
  CHAGE_PASSWORD: 3,
  CHANGE_2FA: 4,
  CREATE_API_KEY: 5,
  CHANGE_API_KEY: 6,
  CHANGE_IP_WHITELIST: 7,
  NEW_DEPOSIT: 8,
  CHANGE_DEPOSIT: 9,
  COMPLETE_DEPOSIT: 10,
  NEW_WITHDRAWAL: 11,
  WITHDRAWAL_IN_BLOCKCHAIN: 12,
  CHANGE_WITHDRAWAL: 13,
  COMPLETE_WITHDRAWAL: 14,
  CANCEL_WITHDRAWAL: 15,
  NEW_CODE: 16,
  REDEEMED_CODE: 17,
  USER_REDEEM_CODE: 18,
  CANCEL_CODE: 19,
  NEW_PRIVATE_MESSAGE: 20,
  NEW_TICKET: 21,
  ANSWER_TICKET: 22,
  NEWS: 23,
  ANALITICS: 24,
  CREATE_REFERRAL_PROGRAM: 25,
  CHANGE_REFERRAL_PROGRAM: 26,
  FILL_ORDER: 27,
  VERIFICATION_COMPLETE: 28,
  VERIFICATION_DECLINED: 29,
  API_NOTIFICATIONS: 32,
  NEW_GROUP_CHAT_MESSAGE: 36,
  TELEGRAM_ID_SET: 46,
  TELEGRAM_ID_REMOVE: 47,
  CONFIRMATION_CODE_RATE_LIMIT: 48,
  TWO_FA_RATE_LIMIT: 49,
  DECLINE_DEPOSIT: 50,
  DECLINE_WITHDRAWAL: 51,
  TRANSACTION_NEED_CONTACT_SUPPORT: 52,
  INTERNAL_DEPOSIT: 55,
  INTERNAL_DEPOSIT_CUMULATIVE: 56,
  IMPORTANT_NEWS: 57,
  MARKETING_NEWS: 58,
  NEW_DEPOSIT_ADMIN_CHECK: 59,
  STORE_ON_MODERATION: 60,
  STORE_MODERATION_FINISHED: 61,
  MERCHANT_ORDER: 62,
  MERCHANT_TRANSACTION: 63,
  INVOICE_STATUS_CHANGED: 64,
  P2P_NOTIFICATION: 31,
  P2P_ARBITR_NOTIFICATION: 33,
  P2P_ADD_USER_TO_TRUSTED: 34,
  P2P_NEW_COMMENT: 35,
  P2P_ENABLE_LIMIT: 37,
  P2P_CHANGE_LIMIT: 38,
  P2P_CREATE_OFFER: 39,
  P2P_STATUS_OFFER: 40,
  P2P_START_TRADE: 41,
  P2P_CONFIRM_SEND_MONEY: 42,
  P2P_CONFIRM_RECEIVE_MONEY: 43,
  P2P_TRADE_RESULT: 44,
  P2P_TRADE_CLOSE: 45,
  P2P_TRADE_TERMINATED: 53,
  P2P_BLOCK_USER: 54,
  P2P_NOTIFICATION_AND_LOCALISATION: 100,
};

const NOTIFICATION_TYPE_LIST = [
  {
    id: NOTIFICATION_TYPE.SIGN_IN,
    caption: 'Sign in',
  },
  {
    id: NOTIFICATION_TYPE.SIGN_UP,
    caption: 'Sign up',
  },
  {
    id: NOTIFICATION_TYPE.UNKNOWN_IP,
    caption: 'Unknown ip',
  },
  {
    id: NOTIFICATION_TYPE.CHAGE_PASSWORD,
    caption: 'Chage password',
  },
  {
    id: NOTIFICATION_TYPE.CHANGE_2FA,
    caption: 'Change 2fa',
  },
  {
    id: NOTIFICATION_TYPE.CREATE_API_KEY,
    caption: 'Create api key',
  },
  {
    id: NOTIFICATION_TYPE.CHANGE_API_KEY,
    caption: 'Change api key',
  },
  {
    id: NOTIFICATION_TYPE.CHANGE_IP_WHITELIST,
    caption: 'Change ip whitelist',
  },
  {
    id: NOTIFICATION_TYPE.NEW_DEPOSIT,
    caption: 'New deposit',
  },
  {
    id: NOTIFICATION_TYPE.CHANGE_DEPOSIT,
    caption: 'Change deposit',
  },
  {
    id: NOTIFICATION_TYPE.COMPLETE_DEPOSIT,
    caption: 'Complete deposit',
  },
  {
    id: NOTIFICATION_TYPE.NEW_WITHDRAWAL,
    caption: 'New withdrawal',
  },
  {
    id: NOTIFICATION_TYPE.WITHDRAWAL_IN_BLOCKCHAIN,
    caption: 'Withdrawal in blockchain',
  },
  {
    id: NOTIFICATION_TYPE.CHANGE_WITHDRAWAL,
    caption: 'Change withdrawal',
  },
  {
    id: NOTIFICATION_TYPE.COMPLETE_WITHDRAWAL,
    caption: 'Complete withdrawal',
  },
  {
    id: NOTIFICATION_TYPE.CANCEL_WITHDRAWAL,
    caption: 'Cancel withdrawal',
  },
  {
    id: NOTIFICATION_TYPE.NEW_CODE,
    caption: 'New code',
  },
  {
    id: NOTIFICATION_TYPE.REDEEMED_CODE,
    caption: 'Redeemed code',
  },
  {
    id: NOTIFICATION_TYPE.USER_REDEEM_CODE,
    caption: 'User redeem code',
  },
  {
    id: NOTIFICATION_TYPE.CANCEL_CODE,
    caption: 'Cancel code',
  },
  {
    id: NOTIFICATION_TYPE.NEW_PRIVATE_MESSAGE,
    caption: 'New private message',
  },
  {
    id: NOTIFICATION_TYPE.NEW_TICKET,
    caption: 'New ticket',
  },
  {
    id: NOTIFICATION_TYPE.ANSWER_TICKET,
    caption: 'Answer ticket',
  },
  {
    id: NOTIFICATION_TYPE.NEWS,
    caption: 'News',
  },
  {
    id: NOTIFICATION_TYPE.ANALITICS,
    caption: 'Analitics',
  },
  {
    id: NOTIFICATION_TYPE.CREATE_REFERRAL_PROGRAM,
    caption: 'Create referral program',
  },
  {
    id: NOTIFICATION_TYPE.CHANGE_REFERRAL_PROGRAM,
    caption: 'Change referral program',
  },
  {
    id: NOTIFICATION_TYPE.FILL_ORDER,
    caption: 'Fill order',
  },
  {
    id: NOTIFICATION_TYPE.VERIFICATION_COMPLETE,
    caption: 'Verification complete',
  },
  {
    id: NOTIFICATION_TYPE.VERIFICATION_DECLINED,
    caption: 'Verification declined',
  },
  {
    id: NOTIFICATION_TYPE.P2P_NOTIFICATION,
    caption: 'P2P notification',
  },
  {
    id: NOTIFICATION_TYPE.API_NOTIFICATIONS,
    caption: 'Api notifications',
  },
  {
    id: NOTIFICATION_TYPE.P2P_ARBITR_NOTIFICATION,
    caption: 'P2P arbitrator notification',
  },
  {
    id: NOTIFICATION_TYPE.P2P_ADD_USER_TO_TRUSTED,
    caption: 'P2P add user to trusted',
  },
  {
    id: NOTIFICATION_TYPE.P2P_NEW_COMMENT,
    caption: 'P2P new comment',
  },
  {
    id: NOTIFICATION_TYPE.NEW_GROUP_CHAT_MESSAGE,
    caption: 'New group chat message',
  },
  {
    id: NOTIFICATION_TYPE.P2P_ENABLE_LIMIT,
    caption: 'P2P enable limit',
  },
  {
    id: NOTIFICATION_TYPE.P2P_CHANGE_LIMIT,
    caption: 'P2P change limit',
  },
  {
    id: NOTIFICATION_TYPE.P2P_CREATE_OFFER,
    caption: 'P2P create offer',
  },
  {
    id: NOTIFICATION_TYPE.P2P_STATUS_OFFER,
    caption: 'P2P status offer',
  },
  {
    id: NOTIFICATION_TYPE.P2P_START_TRADE,
    caption: 'P2P start trade',
  },
  {
    id: NOTIFICATION_TYPE.P2P_CONFIRM_SEND_MONEY,
    caption: 'P2P confirm send money',
  },
  {
    id: NOTIFICATION_TYPE.P2P_CONFIRM_RECEIVE_MONEY,
    caption: 'P2P confirm receive money',
  },
  {
    id: NOTIFICATION_TYPE.P2P_TRADE_RESULT,
    caption: 'P2P trade result',
  },
  {
    id: NOTIFICATION_TYPE.P2P_TRADE_CLOSE,
    caption: 'P2P trade close',
  },
  {
    id: NOTIFICATION_TYPE.TELEGRAM_ID_SET,
    caption: 'Telegram id set',
  },
  {
    id: NOTIFICATION_TYPE.TELEGRAM_ID_REMOVE,
    caption: 'Telegram id remove',
  },
  {
    id: NOTIFICATION_TYPE.CONFIRMATION_CODE_RATE_LIMIT,
    caption: 'Confirmation code rate limit',
  },
  {
    id: NOTIFICATION_TYPE.TWO_FA_RATE_LIMIT,
    caption: 'Two fa rate limit',
  },
  {
    id: NOTIFICATION_TYPE.DECLINE_DEPOSIT,
    caption: 'Decline deposit',
  },
  {
    id: NOTIFICATION_TYPE.DECLINE_WITHDRAWAL,
    caption: 'Decline withdrawal',
  },
  {
    id: NOTIFICATION_TYPE.TRANSACTION_NEED_CONTACT_SUPPORT,
    caption: 'Transaction need contact support',
  },
  {
    id: NOTIFICATION_TYPE.P2P_TRADE_TERMINATED,
    caption: 'P2P trade terminated',
  },
  {
    id: NOTIFICATION_TYPE.P2P_BLOCK_USER,
    caption: 'P2P block user',
  },
  {
    id: NOTIFICATION_TYPE.INTERNAL_DEPOSIT,
    caption: 'Internal deposit',
  },
  {
    id: NOTIFICATION_TYPE.INTERNAL_DEPOSIT_CUMULATIVE,
    caption: 'Internal deposit cumulative',
  },
  {
    id: NOTIFICATION_TYPE.IMPORTANT_NEWS,
    caption: 'Important news',
  },
  {
    id: NOTIFICATION_TYPE.MARKETING_NEWS,
    caption: 'Marketing news',
  },
  {
    id: NOTIFICATION_TYPE.NEW_DEPOSIT_ADMIN_CHECK,
    caption: 'New deposit admin check',
  },
  {
    id: NOTIFICATION_TYPE.STORE_ON_MODERATION,
    caption: 'Store on moderation',
  },
  {
    id: NOTIFICATION_TYPE.STORE_MODERATION_FINISHED,
    caption: 'Store moderation finished',
  },
  {
    id: NOTIFICATION_TYPE.MERCHANT_ORDER,
    caption: 'Merchant order',
  },
  {
    id: NOTIFICATION_TYPE.MERCHANT_TRANSACTION,
    caption: 'Merchant transaction',
  },
  {
    id: NOTIFICATION_TYPE.INVOICE_STATUS_CHANGED,
    caption: 'Invoice status changed',
  },
  {
    id: NOTIFICATION_TYPE.P2P_NOTIFICATION_AND_LOCALISATION,
    caption: 'P2P Notification & Localisation',
  },
].filter((e) => checkConstAvailability(e, { NOTIFICATION_TYPE }));

const EMAIL_MESSAGE_TYPE = {
  CONFIRMATION_EMAIL: 0,
  CONFIRMATION_TRANSACTION: 1,
  RECOVERY_PASSWORD: 2,
  SETUP_ADMIN_ACCOUNT: 3,
  CONFIRMATION_API_KEY: 4,
  CONFIRMATION_DEPOSIT_TRANSACTION: 5,
  NEW_VERIFICATION_REQUEST: 6,
  CONFIRMATION_IP_ADDRESS: 7,
  CONFIRMATION_P2P_TRANSACTION: 8,
  CONFIRMATION_GROUP_CHAT: 9,
  CONFIRMATION_CODE: 10,
  CONFIRMATION_2FA: 11,
  NOTIFICATION: 12,
  NFA: 13,
  NFA_LINK: 14,
  NOTIFICATION_LINK: 15,
  REPORT_COMPLETED: 16,
  ADMIN_NOTIFICATION: 17,
};

const EMAIL_MESSAGE_TYPE_LIST = [
  {
    id: EMAIL_MESSAGE_TYPE.CONFIRMATION_EMAIL,
    caption: 'Confirmation email',
  },
  {
    id: EMAIL_MESSAGE_TYPE.CONFIRMATION_TRANSACTION,
    caption: 'Confirmation transaction',
  },
  {
    id: EMAIL_MESSAGE_TYPE.RECOVERY_PASSWORD,
    caption: 'Recovery password',
  },
  {
    id: EMAIL_MESSAGE_TYPE.SETUP_ADMIN_ACCOUNT,
    caption: 'Setup admin account',
  },
  {
    id: EMAIL_MESSAGE_TYPE.CONFIRMATION_API_KEY,
    caption: 'Confirmation Api key',
  },
  {
    id: EMAIL_MESSAGE_TYPE.CONFIRMATION_DEPOSIT_TRANSACTION,
    caption: 'Confirmation deposit transaction',
  },
  {
    id: EMAIL_MESSAGE_TYPE.NEW_VERIFICATION_REQUEST,
    caption: 'New verification request',
  },
  {
    id: EMAIL_MESSAGE_TYPE.CONFIRMATION_IP_ADDRESS,
    caption: 'Confirmation Ip address',
  },
  {
    id: EMAIL_MESSAGE_TYPE.CONFIRMATION_P2P_TRANSACTION,
    caption: 'Confirmation P2P transaction',
  },
  {
    id: EMAIL_MESSAGE_TYPE.CONFIRMATION_GROUP_CHAT,
    caption: 'Confirmation group chat',
  },
  {
    id: EMAIL_MESSAGE_TYPE.CONFIRMATION_CODE,
    caption: 'Confirmation code',
  },
  {
    id: EMAIL_MESSAGE_TYPE.CONFIRMATION_2FA,
    caption: 'Confirmation 2Fa',
  },
  {
    id: EMAIL_MESSAGE_TYPE.NOTIFICATION,
    caption: 'Notification',
  },
  {
    id: EMAIL_MESSAGE_TYPE.NFA,
    caption: 'Mfa',
  },
  {
    id: EMAIL_MESSAGE_TYPE.NFA_LINK,
    caption: 'Mfa link',
  },
  {
    id: EMAIL_MESSAGE_TYPE.NOTIFICATION_LINK,
    caption: 'Notification link',
  },
  {
    id: EMAIL_MESSAGE_TYPE.REPORT_COMPLETED,
    caption: 'Report completed',
  },
  {
    id: EMAIL_MESSAGE_TYPE.ADMIN_NOTIFICATION,
    caption: 'Admin notification',
  },
].filter((e) => checkConstAvailability(e, { EMAIL_MESSAGE_TYPE }));

const NOTIFICATION_MESSAGE_TYPE = {
  GROUP_CHAT: 0,
  NOTIFICATION: 1,
};

const NOTIFICATION_MESSAGE_TYPE_LIST = [
  {
    id: NOTIFICATION_MESSAGE_TYPE.GROUP_CHAT,
    caption: 'Group chat',
  },
  {
    id: NOTIFICATION_MESSAGE_TYPE.NOTIFICATION,
    caption: 'Notification',
  },
];

const NOTIFICATION_DESTINATION_TYPE = {
  OFFERS: 0,
  TRADES: 1,
};

const NOTIFICATION_DESTINATION_TYPE_LIST = [
  {
    id: NOTIFICATION_DESTINATION_TYPE.OFFERS,
    caption: 'Offers',
  },
  {
    id: NOTIFICATION_DESTINATION_TYPE.TRADES,
    caption: 'Trades',
  },
];

const WARNING_TYPES = {
  WARNING: 0,
  ERROR: 1,
};

const WARNINGS_NAMES = {
  EMPTY_NOTIFICATIONS: 'EMPTY_NOTIFICATIONS',
  DUPLICATE_NOTIFICATIONS: 'DUPLICATE_NOTIFICATIONS',
  FIRST_MESSAGE: 'FIRST_MESSAGE',
};

const WARNINGS_LIST = {
  EMPTY_NOTIFICATIONS: {
    type: WARNING_TYPES.WARNING,
    icon: 'alert',
    title: 'Empty notifications found',
    description: 'Please check your notifications and make sure that there are no empty ones that do not contain any messages',
  },
  DUPLICATE_NOTIFICATIONS: {
    type: WARNING_TYPES.ERROR,
    icon: 'alert-octagon',
    title: 'Duplicates found in P2P notification settings',
    description: 'Please make sure you dont have any duplicate messages before saving',
  },
  FIRST_MESSAGE: {
    type: WARNING_TYPES.WARNING,
    icon: 'alert',
    title: 'Send a message to join chat',
    description: 'To join a chat and get new messages instantly type and send your first message',
  },
};

const NOTIFICATION_SERVICE_TYPE = {
  WS: 0,
  SYSTEM: 1,
  EMAIL: 2,
  TELEGRAM: 3,
  SOUND: 5,
  PUSH: 6,
};

const NOTIFICATION_SERVICE_TYPE_LIST = [
  {
    id: NOTIFICATION_SERVICE_TYPE.WS,
    caption: 'Pop-up',
  },
  {
    id: NOTIFICATION_SERVICE_TYPE.SYSTEM,
    caption: 'System',
  },
  {
    id: NOTIFICATION_SERVICE_TYPE.EMAIL,
    caption: 'Email',
  },
  {
    id: NOTIFICATION_SERVICE_TYPE.TELEGRAM,
    caption: 'Telegram',
  },
  {
    id: NOTIFICATION_SERVICE_TYPE.SOUND,
    caption: 'Sound',
  },
  {
    id: NOTIFICATION_SERVICE_TYPE.PUSH,
    caption: 'Push',
  },
].filter((e) => checkConstAvailability(e, { NOTIFICATION_SERVICE_TYPE }));

export default {
  LANGUAGE,
  LANGUAGE_LIST,
  EMAIL_BODY_TYPE,
  EMAIL_BODY_TYPE_LIST,
  NOTIFICATION_TYPE,
  NOTIFICATION_TYPE_LIST,
  EMAIL_MESSAGE_TYPE,
  EMAIL_MESSAGE_TYPE_LIST,
  NOTIFICATION_SERVICE_TYPE,
  NOTIFICATION_SERVICE_TYPE_LIST,
  NOTIFICATION_MESSAGE_TYPE,
  NOTIFICATION_MESSAGE_TYPE_LIST,
  NOTIFICATION_DESTINATION_TYPE,
  NOTIFICATION_DESTINATION_TYPE_LIST,
  WARNING_TYPES,
  WARNINGS_NAMES,
  WARNINGS_LIST,
};
