import http from '~/http/index';

export default ({
  state: {
    data: [],
    countries: [],
    cities: [],
    filters: {},
    sort: {
      sort: 'sortId',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, page, sort } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...sort,
        ...filters,
        page: page - 1,
        limit: itemsPerPage,
        getTotal: true,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },
    SET_COUNTRIES(state, data) {
      state.countries = data;
    },
    SET_CITIES(state, data) {
      state.cities = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, payload) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.get('Transaction/GetPaymentMethods', {
        params: {
          ...getters.requestData,
          ...payload,
        },
      });
      const { items, total } = response.data;
      commit('SET_DATA', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async getPaymentMethods(__store, params) {
      const response = await http.get('Transaction/GetPaymentMethods', { params });
      return response.data;
    },

    async editPaymentMethod(__store, params) {
      await http.post('Transaction/EditPaymentMethod', params);
    },

    async createPaymentMethod(__store, params) {
      const response = await http.post('Transaction/CreatePaymentMethod', params);
      return response.data;
    },

    async deletePaymentMethod(__store, id) {
      const response = await http.post('Transaction/DeletePaymentMethod', { id });
      return response;
    },

    async setP2PIcon(__store, { params, data }) {
      await http.post(`Transaction/SetPaymentMethodIcon?paymentMethodId=${params.paymentMethodId}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },

    async getCountries({ commit }) {
      const response = await http.get('Transaction/GetCountries');
      commit('SET_COUNTRIES', response.data.items);
    },

    async getCities({ commit }) {
      const response = await http.get('Transaction/GetCities');
      commit('SET_CITIES', response.data.items);
    },

    async getCountryPaymentMethods(__store, params) {
      const response = await http.get('Transaction/GetCountryPaymentMethods', { params });
      return response.data;
    },

    // async getCityPaymentMethods(__store, params) {
    //   const response = await http.get('Transaction/GetCityPaymentMethods', { params });
    //   return response.data;
    // },

    async createCountryPaymentMethod(__store, data) {
      const response = await http.post('Transaction/CreateCountryPaymentMethod', data);
      return response.data;
    },

    // async createCityPaymentMethod(__store, data) {
    //   const response = await http.post('Transaction/CreateCityPaymentMethod', data);
    //   return response.data;
    // },

    async deleteCountryPaymentMethod(__store, data) {
      const response = await http.post('Transaction/DeleteCountryPaymentMethod', data);
      return response.data;
    },

    // async deleteCityPaymentMethod(__store, data) {
    //   const response = await http.post('Transaction/DeleteCityPaymentMethod', data);
    //   return response.data;
    // },

    async createPaymentMethodField(__store, data) {
      const response = await http.post('Transaction/CreatePaymentMethodField', data);
      return response.data;
    },

    async editPaymentMethodField(__store, data) {
      const response = await http.put('Transaction/EditPaymentMethodField', data);
      return response.data;
    },

    async deletePaymentMethodField(__store, data) {
      const response = await http.post('Transaction/DeletePaymentMethodField', data);
      return response.data;
    },

    async createCountry(__store, data) {
      const response = await http.post('Transaction/CreateCountry', data);
      return response.data;
    },

    async editCountry(__store, data) {
      const response = await http.put('Transaction/EditCountry', data);
      return response.data;
    },

    async deleteCountry(__store, data) {
      const response = await http.put('Transaction/DeleteCountry', data);
      return response.data;
    },

    async createCity(__store, data) {
      const response = await http.post('Transaction/CreateCity', data);
      return response.data;
    },

    async editCity(__store, data) {
      const response = await http.put('Transaction/EditCity', data);
      return response.data;
    },

    async deleteCity(__store, data) {
      const response = await http.put('Transaction/DeleteCity', data);
      return response.data;
    },
  },

  namespaced: true,
});
