import moment from 'moment';
import http from '~/http/new';

function dataURItoBlob(dataURI) {
  const byteString = window.atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: mimeString });
}

function strToBlobFile(str, type) {
  const byteString = window.atob(str.split(',')[1]);
  const mimeString = str.split(',')[0].split(':')[1].split(';')[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const bufView = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i += 1) {
    bufView[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: type ?? mimeString });
}

export default ({
  state: {
    filesData: {},
    data: [],
    filters: {},
    imageLoading: true,
    sort: {
      sort: 'defaultSort',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
    chatMessages: [],
    chatMessagesTotal: null,
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const {
        filters,
        page,
      } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const formattedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if (value || value === 0) {
          acc[key] = value;
        }
        return acc;
      }, {});
      return {
        ...formattedFilters,
        dateFrom: filters.dateFrom ? moment.utc(filters.dateFrom).unix() : undefined,
        dateTo: filters.dateTo ? moment.utc(filters.dateTo).set({ hour: 23, minute: 59, second: 59 }).unix() : undefined,
        // ...sort,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
      };
    },

    reportData(state) {
      const {
        filters,
      } = state;

      const formattedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if (value || value === 0) {
          acc[key] = value;
        }
        return acc;
      }, {});

      return {
        ...formattedFilters,
        dateFrom: filters.dateFrom ? String(moment.utc(filters.dateFrom).unix()) : undefined,
        dateTo: filters.dateTo ? String(moment.utc(filters.dateTo).set({ hour: 23, minute: 59, second: 59 }).unix()) : undefined,
      };
    },

    sortedByDateChatMessages(state) {
      return state.chatMessages
        .filter((message) => message.chatMemberType !== 1)
        .sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated));
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_CHAT_MESSAGES(state, data) {
      state.chatMessages = data;
    },

    SET_CHAT_MESSAGES_TOTAL(state, data) {
      state.chatMessagesTotal = data;
    },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_FILES_DATA(state, data) {
      state.filesData = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.get('v1/p2p/trades', {
        params: {
          ...getters.requestData,
          ...data,
        },
      });
      const { items, total } = response.data;
      commit('SET_DATA', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async getChatMessages({ state, commit, dispatch }, params) {
      const response = await http.get('api/bo/User/GetGroupChatMessagesDetail', {
        params,
      });
      const { items, total } = response.data;
      commit('SET_CHAT_MESSAGES', items);
      commit('SET_CHAT_MESSAGES_TOTAL', total);
      if (items.length) {
        items.forEach((message) => {
          if (message.files?.length) {
            message.files.forEach(async (file) => {
              if (['jpeg', 'jpg', 'png'].includes(file.extension)) {
                await dispatch('appealGetFile', file);
                state.imageLoading = false;
              }
              state.imageLoading = false;
            });
          }
        });
      }
    },

    async statusNotification(__store, ids) {
      const response = await http.put('v1/p2p/trades/notify', {
        params: {
          TradeIds: [...ids],
        },
      });
      return response;
    },

    async editDeal(__store, data) {
      const response = await http.put(`v1/p2p/trades/${data.tradeId}`, data);
      return response.data;
    },

    async appealDownloadFile({ dispatch }, file) {
      const { name, extension } = file;

      try {
        const data = await dispatch('appealGetFile', file);

        if (data) {
          const loadedFile = dataURItoBlob(data);
          const url = URL.createObjectURL(loadedFile);

          if (typeof window === 'object') {
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.setAttribute('download', `${name}.${extension}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
          } else {
            throw new Error('getFile should run only on client');
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },

    async appealGetFile({ state, commit }, file) {
      if (!state.filesData[file.id]) {
        const { data } = await http.get('api/bo/User/GetUserFile', { params: { UserFileId: file.id } });

        if (data) {
          const newFilesData = {
            ...state.filesData,
            [file.id]: data,
          };
          commit('SET_FILES_DATA', newFilesData);
        }
      }
      return state.filesData[file.id];
    },

    async sendChatMessage(_, { groupChatId, message = '', files }) {
      const data = new FormData();

      data.append('GroupChatId', groupChatId);

      if (files && files.length) {
        files.forEach((file) => {
          const blob = strToBlobFile(file.data, file.file.type);
          data.append('Files', blob, file.file.name);
        });
      }

      if (message) {
        data.append('Message', message);
      }

      await http.post('api/bo/User/SendGroupChatMessage', data);
    },
  },

  namespaced: true,
});
