import http from '~/http/index';

export default ({
  state: {
    data: {},
  },

  getters: {
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
  },

  actions: {
    async loadData({ commit }) {
      const response = await http.get('v2/System/GetWatermarks');
      commit('SET_DATA', response.data);
    },

    async uploadImage(__store, data) {
      await http.post('v2/System/UploadWatermarkImage', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },

    async uploadLogo(__store, data) {
      await http.post('v2/System/UploadWatermarkLogo', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
  },

  namespaced: true,
});
