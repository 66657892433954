export default [
  {
    path: 'fees',
    name: 'statistics-fees',
    component: () => import(/* webpackChunkName: "statistics-fees" */ '~/views/StatisticsFees/StatisticsFees.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderStatistics' },
    },
  },
  {
    path: 'trades',
    name: 'statistics-trades',
    component: () => import(/* webpackChunkName: "statistics-trades" */ '~/views/StatisticsTrades/StatisticsTrades.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderStatistics' },
    },
  },
];
