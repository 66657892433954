export default [
  {
    path: '',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '~/views/Users/UsersTable.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUsers' },
    },
  },

  {
    path: 'requests',
    name: 'users-requests',
    component: () => import(/* webpackChunkName: "users-requests" */ '~/views/Users/UsersRequests/Requests.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUsers' },
      rootRouteName: 'users-requests',
    },
  },
];
