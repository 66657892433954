const CHAT_MEMBER_TYPE = {
  CUSTOMER: 0,
  SYSTEM: 1,
  ADMIN: 2,
  ARBITRATOR: 3,
};

const IMAGE_EXTENSION = [
  'jpeg',
  'jpg',
  'png',
  'heif',
  'heic',
];

export default {
  CHAT_MEMBER_TYPE,
  IMAGE_EXTENSION,
};
