import checkConstAvailability from '~/utils/check-const-availability';

const REQUEST_PROFILE_TYPES = {
  TYPE_USER: 0,
  TYPE_ADMIN: 1,
  TYPE_ARBITRATOR: 2,
  TYPE_MERCHANT: 3,
};

const REQUEST_PROFILE_TYPES_LIST = [
  {
    id: REQUEST_PROFILE_TYPES.TYPE_USER,
    caption: 'User',
  },
  {
    id: REQUEST_PROFILE_TYPES.TYPE_ADMIN,
    caption: 'Admin',
  },
  {
    id: REQUEST_PROFILE_TYPES.TYPE_ARBITRATOR,
    caption: 'Arbitrator',
  },
  {
    id: REQUEST_PROFILE_TYPES.TYPE_MERCHANT,
    caption: 'Merchant',
  },
].filter((e) => checkConstAvailability(e, { REQUEST_PROFILE_TYPES }));

const ONLINE_STATUS = {
  OFFLINE: 0,
  ONLINE: 1,
  AWAY: 2,
};

const NFA_SERVICE_TYPE = {
  EMAIL: 0,
  TELEGRAM_BOT: 1,
  TOTP: 2,
  SMS: 3,
  CODES: 4,
  QUESTION: 5,
  U2F: 6,
  GPA: 7,
  PASSWORD: 8,
};

const NFA_SERVICE_TYPE_LIST = [
  {
    id: NFA_SERVICE_TYPE.EMAIL,
    caption: 'Email',
  },
  {
    id: NFA_SERVICE_TYPE.TELEGRAM_BOT,
    caption: 'Telegram Bot',
  },
  {
    id: NFA_SERVICE_TYPE.TOTP,
    caption: 'TOTP',
  },
  {
    id: NFA_SERVICE_TYPE.SMS,
    caption: 'SMS',
  },
  {
    id: NFA_SERVICE_TYPE.CODES,
    caption: 'Codes',
  },
  {
    id: NFA_SERVICE_TYPE.QUESTION,
    caption: 'Question',
  },
  {
    id: NFA_SERVICE_TYPE.U2F,
    caption: 'U2F',
  },
  {
    id: NFA_SERVICE_TYPE.GPA,
    caption: 'GPA',
  },
  {
    id: NFA_SERVICE_TYPE.PASSWORD,
    caption: 'Password',
  },
].filter((e) => checkConstAvailability(e, { NFA_SERVICE_TYPE }));

const NFA_SECTION_TYPE = {
  OTHER: 0,
  SIGN_IN: 1,
  IP_WHITELIST: 2,
  API_KEY: 3,
  CHANGE_PASSWORD: 4,
  WITHDRAWAL: 5,
  DEPOSIT: 6,
  P2P: 7,
  NFA_SETTINGS: 8,
  // SIGN_IN: 0,
  // TRANSACTION: 1,
  // API_KEYS: 2,
  // IP_WHITELIST: 3,
  // P2P: 4,
  // RECOVER_PASSWORD: 5,
};

const NFA_SECTION_TYPE_LIST = [
  { id: NFA_SECTION_TYPE.OTHER, caption: 'Other' },
  { id: NFA_SECTION_TYPE.SIGN_IN, caption: 'Sign in' },
  { id: NFA_SECTION_TYPE.IP_WHITELIST, caption: 'Ip whitelist' },
  { id: NFA_SECTION_TYPE.API_KEY, caption: 'Api key' },
  { id: NFA_SECTION_TYPE.CHANGE_PASSWORD, caption: 'Change password' },
  { id: NFA_SECTION_TYPE.WITHDRAWAL, caption: 'Withdrawal' },
  { id: NFA_SECTION_TYPE.DEPOSIT, caption: 'Deposit' },
  { id: NFA_SECTION_TYPE.P2P, caption: 'P2P' },
  { id: NFA_SECTION_TYPE.NFA_SETTINGS, caption: 'Mfa settings' },
  // {
  //   id: NFA_SECTION_TYPE.SIGN_IN,
  //   caption: 'Sign in',
  // },
  // {
  //   id: NFA_SECTION_TYPE.TRANSACTION,
  //   caption: 'Transaction',
  // },
  // {
  //   id: NFA_SECTION_TYPE.API_KEYS,
  //   caption: 'Api keys',
  // },
  // {
  //   id: NFA_SECTION_TYPE.IP_WHITELIST,
  //   caption: 'Ip whitelist',
  // },
  // {
  //   id: NFA_SECTION_TYPE.P2P,
  //   caption: 'P2P',
  // },
  // {
  //   id: NFA_SECTION_TYPE.RECOVER_PASSWORD,
  //   caption: 'Recover password',
  // },
].filter((e) => checkConstAvailability(e, { NFA_SECTION_TYPE }));

const WARNING_TYPE = {
  TELEGRAM_ID_IN_USE: 0,
  PHONE_IN_USE: 1,
  EMAIL_IN_USE: 2,
  ORDER_INSUFFICIENT_FUNDS: 3,
  TRANSACTION_INSUFFICIENT_FUNDS: 4,
  TRANSACTION_BAD_REQUISITES: 5,
  IP_ADDRESS: 6,
  USER_AGENT: 7,
  DOCUMENT_NUMBER: 8,
  P2P_INSUFFICIENT_FUNDS: 9,
  NEW_IP_ADDRESS: 10,
  NEW_IP_ADDRESS_TRANSACTION: 11,
  UTM_COUNT: 12,
};

const WARNING_TYPE_LIST = [
  {
    id: WARNING_TYPE.TELEGRAM_ID_IN_USE,
    caption: 'Telegram id in use',
  },
  {
    id: WARNING_TYPE.PHONE_IN_USE,
    caption: 'Phone in use',
  },
  {
    id: WARNING_TYPE.EMAIL_IN_USE,
    caption: 'Email in use',
  },
  {
    id: WARNING_TYPE.ORDER_INSUFFICIENT_FUNDS,
    caption: 'Order insufficient funds',
  },
  {
    id: WARNING_TYPE.TRANSACTION_INSUFFICIENT_FUNDS,
    caption: 'Transaction insufficient funds',
  },
  {
    id: WARNING_TYPE.TRANSACTION_BAD_REQUISITES,
    caption: 'Transaction bad requisites',
  },
  {
    id: WARNING_TYPE.IP_ADDRESS,
    caption: 'Ip address',
  },
  {
    id: WARNING_TYPE.USER_AGENT,
    caption: 'User agent',
  },
  {
    id: WARNING_TYPE.DOCUMENT_NUMBER,
    caption: 'Document number',
  },
  {
    id: WARNING_TYPE.P2P_INSUFFICIENT_FUNDS,
    caption: 'P2P insufficient funds',
  },
  {
    id: WARNING_TYPE.NEW_IP_ADDRESS,
    caption: 'New IP address',
  },
  {
    id: WARNING_TYPE.NEW_IP_ADDRESS_TRANSACTION,
    caption: 'New IP address transaction',
  },
  {
    id: WARNING_TYPE.UTM_COUNT,
    caption: 'UTM warnigNumber of clicks:',
  },
].filter((e) => checkConstAvailability(e, { WARNING_TYPE }));

const USER_LOCK_TYPE = {
  GENERAL: 0,
  LOGIN: 1,
  CHAT: 2,
  PRIVATE_CHAT: 3,
  GROUP_CHAT: 4,
  CODE: 5,
  CODE_BY_SYSTEM: 6,
  WITHDRAWAL: 7,
  TWO_FACTOR: 8,
  CONFIRMATION_CODE: 9,
  P2P_OFFERS: 10,
  P2P_TRADES: 11,
  TELEGRAM_ID: 12,
  P2P_SELL_CRYPTO: 13,
  ALL_WITHDRAWAL: 14,
};

const USER_LOCK_TYPE_LIST = [
  {
    id: USER_LOCK_TYPE.ALL_WITHDRAWAL,
    caption: 'All Withdrawal',
  },
  {
    id: USER_LOCK_TYPE.GENERAL,
    caption: 'General',
  },
  {
    id: USER_LOCK_TYPE.LOGIN,
    caption: 'Login',
  },
  {
    id: USER_LOCK_TYPE.CHAT,
    caption: 'Chat',
  },
  {
    id: USER_LOCK_TYPE.PRIVATE_CHAT,
    caption: 'Private chat',
  },
  {
    id: USER_LOCK_TYPE.GROUP_CHAT,
    caption: 'Group chat',
  },
  {
    id: USER_LOCK_TYPE.CODE,
    caption: 'Code',
  },
  {
    id: USER_LOCK_TYPE.CODE_BY_SYSTEM,
    caption: 'Code by system',
  },
  {
    id: USER_LOCK_TYPE.WITHDRAWAL,
    caption: 'Withdrawal',
  },
  {
    id: USER_LOCK_TYPE.TWO_FACTOR,
    caption: 'Two factor',
  },
  {
    id: USER_LOCK_TYPE.CONFIRMATION_CODE,
    caption: 'Confirmation code',
  },
  {
    id: USER_LOCK_TYPE.P2P_OFFERS,
    caption: 'P2P offers',
  },
  {
    id: USER_LOCK_TYPE.P2P_TRADES,
    caption: 'P2P trades',
  },
  {
    id: USER_LOCK_TYPE.TELEGRAM_ID,
    caption: 'Telegram id',
  },
  {
    id: USER_LOCK_TYPE.P2P_SELL_CRYPTO,
    caption: 'P2P sell crypto',
  },
].filter((e) => checkConstAvailability(e, { USER_LOCK_TYPE }));

const USER_LOCK_TYPE_LIST_P2PAY = [
  {
    id: USER_LOCK_TYPE.ALL_WITHDRAWAL,
    caption: 'All Withdrawal',
  },
  {
    id: USER_LOCK_TYPE.GENERAL,
    caption: 'General',
  },
  {
    id: USER_LOCK_TYPE.LOGIN,
    caption: 'Login',
  },
  {
    id: USER_LOCK_TYPE.WITHDRAWAL,
    caption: 'Withdrawal',
  },
  {
    id: USER_LOCK_TYPE.P2P_OFFERS,
    caption: 'P2P offers',
  },
  {
    id: USER_LOCK_TYPE.P2P_TRADES,
    caption: 'P2P trades',
  },
  {
    id: USER_LOCK_TYPE.P2P_SELL_CRYPTO,
    caption: 'P2P sell crypto',
  },
].filter((e) => checkConstAvailability(e, { USER_LOCK_TYPE }));

const USER_STATUS = {
  NEW: 0,
  CONFIRMED: 1,
  VERIFICATION_REQUEST: 2,
  VERIFICATED: 3,
  REJECTED: 4,
  VERIFIED_VERIFICATION_REQUEST: 5,
};

const USER_STATUS_LIST = [
  {
    id: USER_STATUS.NEW,
    caption: 'New',
  },
  {
    id: USER_STATUS.CONFIRMED,
    caption: 'Confirmed',
  },
  {
    id: USER_STATUS.VERIFICATION_REQUEST,
    caption: 'Verification request',
  },
  {
    id: USER_STATUS.VERIFICATED,
    caption: 'Verificated',
  },
  {
    id: USER_STATUS.REJECTED,
    caption: 'Rejected',
  },
  {
    id: USER_STATUS.VERIFIED_VERIFICATION_REQUEST,
    caption: 'Verified verification request',
  },
].filter((e) => checkConstAvailability(e, { USER_STATUS }));

const IDENTITY_CLIENT_TYPE = {
  SITE: 0,
  MOBILE: 1,
  ADMIN: 2,
  CRYPTOMAT: 3,
  UNDEFINED: 4,
  PAYMENT: 5,
};


const IDENTITY_CLIENT_TYPE_LIST = [
  {
    id: IDENTITY_CLIENT_TYPE.SITE,
    caption: 'Site',
  },
  {
    id: IDENTITY_CLIENT_TYPE.MOBILE,
    caption: 'Mobile',
  },
  {
    id: IDENTITY_CLIENT_TYPE.ADMIN,
    caption: 'Admin',
  },
  {
    id: IDENTITY_CLIENT_TYPE.CRYPTOMAT,
    caption: 'Cryptomat',
  },
  {
    id: IDENTITY_CLIENT_TYPE.UNDEFINED,
    caption: 'Undefined',
  },
  {
    id: IDENTITY_CLIENT_TYPE.PAYMENT,
    caption: 'Payment',
  },
].filter((e) => checkConstAvailability(e, { IDENTITY_CLIENT_TYPE }));

const DEFAULT_USER_TAG_COLOR = '#BDBDBD';

const USER_TYPE = {
  REGULAR: 0,
  MARKET_MAKER: 1,
  TRADER: 2,
  VIP: 3,
  CRYPTOMAT_MASTER: 4,
  AGGREGATOR: 5,
  PAYMENT_MASTER: 6,
  SWAP_TRADER: 7,
  ADMIN: 8,
  ARBITRATOR: 9,
};

const USER_TYPE_LIST = [
  {
    id: USER_TYPE.REGULAR,
    caption: 'Regular',
  },
  {
    id: USER_TYPE.MARKET_MAKER,
    caption: 'Market maker',
  },
  {
    id: USER_TYPE.TRADER,
    caption: 'Trader',
  },
  {
    id: USER_TYPE.VIP,
    caption: 'Vip',
  },
  {
    id: USER_TYPE.CRYPTOMAT_MASTER,
    caption: 'Cryptomat master',
  },
  {
    id: USER_TYPE.AGGREGATOR,
    caption: 'Aggregator',
  },
  {
    id: USER_TYPE.PAYMENT_MASTER,
    caption: 'Payment master',
  },
  {
    id: USER_TYPE.SWAP_TRADER,
    caption: 'Swap trader',
  },
  // {
  //   id: USER_TYPE.ADMIN,
  //   caption: 'Admin',
  // },
  {
    id: USER_TYPE.ARBITRATOR,
    caption: 'Arbitrator',
  },
];

const USER_ROLE = {
  // REGULAR: 0,
  TRADER: 1,
  MERCHANT: 2,
  // ARBITRATOR: 3,
  // ADMIN: 4,
  // CRYPTOMAT_MASTER: 5,
  // PAYMENT_MASTER: 6,
  // MARKET_MAKER: 7,
};

const USER_ROLE_CAPTION_IOTA = {
  // 0: 'Regular',
  1: 'Trader',
  2: 'Merchant',
  // 3: 'Arbitrator',
  // 4: 'Admin',
  // 5: 'CRYPTOMAT_MASTER',
  // 6: 'PAYMENT_MASTER',
  // 7: 'MARKET_MAKER',
};

const USER_ROLE_LIST_P2PAY = {
  0: 'UserRole_Regular',
  1: 'UserRole_Trader',
  2: 'UserRole_Merchant',
  3: 'UserRole_Arbitrator',
  4: 'UserRole_Admin',
  5: 'UserRole_CryptomatMaster',
  6: 'UserRole_PaymentMaster',
  7: 'UserRole_MarketMaker',
};


const USER_ROLE_LIST = [
  // {
  //   id: USER_ROLE.REGULAR,
  //   caption: 'Regular',
  // },
  {
    id: USER_ROLE.TRADER,
    caption: 'Trader',
  },
  {
    id: USER_ROLE.MERCHANT,
    caption: 'Merchant',
  },
  // {
  //   id: USER_ROLE.ARBITRATOR,
  //   caption: 'Arbitrator',
  // },
  // {
  //   id: USER_ROLE.ADMIN,
  //   caption: 'Admin',
  // },
];

// eslint-disable-next-line
export const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// eslint-disable-next-line
export const PASSWORD_REGEXP_SIGNUP = /^(?=\S*\d)(?=\S*[.+'!@?#$№%&*()\/|<{}>\]\[()=`~,;:"])?(?=\S*[a-z])(?=\S*[A-Z])\S{8,64}$/;

export const USERNAME_REGEXP = /^[a-zA-Z][a-zA-Z0-9_]{5,31}$/;

export default {
  ONLINE_STATUS,
  NFA_SERVICE_TYPE,
  NFA_SERVICE_TYPE_LIST,
  NFA_SECTION_TYPE,
  NFA_SECTION_TYPE_LIST,
  WARNING_TYPE,
  WARNING_TYPE_LIST,
  USER_LOCK_TYPE,
  USER_LOCK_TYPE_LIST,
  USER_LOCK_TYPE_LIST_P2PAY,
  USER_STATUS,
  USER_STATUS_LIST,
  IDENTITY_CLIENT_TYPE,
  IDENTITY_CLIENT_TYPE_LIST,
  DEFAULT_USER_TAG_COLOR,
  USER_TYPE,
  USER_TYPE_LIST,
  USER_ROLE,
  USER_ROLE_CAPTION_IOTA,
  USER_ROLE_LIST,
  EMAIL_REGEXP,
  PASSWORD_REGEXP_SIGNUP,
  USERNAME_REGEXP,
  REQUEST_PROFILE_TYPES,
  REQUEST_PROFILE_TYPES_LIST,
  USER_ROLE_LIST_P2PAY,
};
