const LANGUAGES_CODES = {
  en: 0,
  ru: 1,
  zh: 2,
  es: 3,
  et: 4,
  tr: 5,
};

const LANGUAGES_LIST = [
  {
    id: LANGUAGES_CODES.en,
    caption: 'English',
    code: 'En',
  },
  {
    id: LANGUAGES_CODES.ru,
    caption: 'Russian',
    code: 'Ru',
  },
  {
    id: LANGUAGES_CODES.zh,
    caption: 'Chinese',
    code: 'Zh',
  },
  {
    id: LANGUAGES_CODES.es,
    caption: 'Spanish',
    code: 'Es',
  },
  {
    id: LANGUAGES_CODES.et,
    caption: 'Estonian',
    code: 'Et',
  },
  {
    id: LANGUAGES_CODES.tr,
    caption: 'Turkish',
    code: 'Tr',
  },
];

const CIS_CODES = {
  AM: 0,
  AZ: 1,
  BY: 2,
  KZ: 3,
  KG: 4,
  MD: 5,
  RU: 6,
  TJ: 7,
  TM: 8,
  UZ: 9,
  UA: 10,
};

const CIS_LIST = [
  {
    id: CIS_CODES.AM,
    caption: 'Armenia',
  },
  {
    id: CIS_CODES.AZ,
    caption: 'Azerbaijan',
  },
  {
    id: CIS_CODES.BY,
    caption: 'Belarus',
  },
  {
    id: CIS_CODES.KZ,
    caption: 'Kazakhstan',
  },
  {
    id: CIS_CODES.KG,
    caption: 'Kyrgyzstan',
  },
  {
    id: CIS_CODES.MD,
    caption: 'Moldova',
  },
  {
    id: CIS_CODES.RU,
    caption: 'Russia',
  },
  {
    id: CIS_CODES.TJ,
    caption: 'Tajikistan',
  },
  {
    id: CIS_CODES.TM,
    caption: 'Turkmenistan',
  },
  {
    id: CIS_CODES.UZ,
    caption: 'Uzbekistan',
  },
  {
    id: CIS_CODES.UA,
    caption: 'Ukraine',
  },
];

export default {
  LANGUAGES_LIST,
  LANGUAGES_CODES,
  CIS_LIST,
  CIS_CODES,
};
