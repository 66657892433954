import newhttp from '~/http/new';

export default ({
  state: {
    data: [],
    page: 1,
    pages: 0,
    limit: 10,
  },

  mutations: {
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },
    SET_DATA(state, data) { state.data = data; },
    SET_ALL_DATA(state, data) { state.allData = data; },
  },

  getters: {
    requestData(state) {
      const {
        limit,
        page,
      } = state;
      return {
        offset: (page - 1) * limit,
        isArbitratorJoined: false,
        isInDispute: true,
        limit,
      };
    },
  },

  actions: {
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ state, commit, getters }, dataObj) {
      const { limit } = state;
      const response = await newhttp.get('v1/arbitrator/trades', {
        params: {
          ...getters.requestData,
          ...dataObj,
        },
      });
      const { data, total } = response.data;
      commit('SET_DATA', data);
      if (total) {
        const pages = Math.ceil(total / limit);
        commit('SET_PAGES', pages);
      }
    },

    async join(__store, id) {
      const response = await newhttp.put(`v1/arbitrator/trades/${id}/join`, { id });
      if (!response?.data?.result?.Success) {
        throw new Error(`You are not joined to trade because of Error: "${response?.data?.result?.Error}"`);
      }
      return response.data;
    },

    async assign(__store, id) {
      const response = await newhttp.post(`v1/arbitrator/trades/${id}/assign`, { id });
      if (!response?.data?.result?.Success) {
        throw new Error(`You are not assigned to trade because of Error: "${response?.data?.result?.Error}"`);
      }
      return response.data;
    },
  },

  namespaced: true,
});
