export default [
  { title: 'Awating Arbitration', routeName: 'arbitrator-awaiting' },

  { title: 'My arbitration', routeName: 'arbitrator-my-arbitration' },

  { title: 'Others arbitration', routeName: 'arbitrator-others-arbitration' },

  { title: 'All deals', routeName: 'arbitrator-all-deals' },

];
