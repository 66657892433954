import axios from 'axios';
import moment from 'moment';
import http from '~/http/index';
import newhttp from '~/http/new';

export default ({
  state: {},

  getters: {},

  mutations: {},

  actions: {
    async createReport(__store, data) {
      const type = process.env.VUE_APP_EXCLUDE_FEATURE?.split(' ').includes('transactions-old');
      const url = type ? 'v1/reports' : 'v2/Report/CreateReport';
      const protocol = type ? newhttp : http;
      const method = type ? 'post' : 'put';
      const response = await protocol[method](url, data);
      return response.data;
    },

    async downloadReport(url, reportType) {
      const date = new Date();
      const dateString = moment(date).format('YYYY-MM-DD, hh:mm:ss');
      const fileName = `Report ${reportType} ${dateString}.csv`;
      const response = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      });
      const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = objectUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    },
  },

  namespaced: true,
});
