import loginModule from '@/store/modules/login';

import navigationStatistics from '~/config/navigationStatistics';
import navigationSettings from '~/config/navigationSettings';
import navigationP2P from '~/config/navigationP2P';
import navigationTraders from '~/config/navigationTraders';
import navigationPaymentSystemsAndCurrencies from '~/config/navigationPaymentSystemsAndCurrencies';
import navigationFinance from '~/config/navigationFinance';
import navigationArbitrator from '~/config/navigationArbitrator';

const REDIRECT_FALLBACK_ROUTE_NAME = 'account';

const statisticsRouteName = navigationStatistics[0]?.routeName || REDIRECT_FALLBACK_ROUTE_NAME;
const settingsRouteName = navigationSettings[0]?.routeName || REDIRECT_FALLBACK_ROUTE_NAME;
const p2pRouteName = navigationP2P[0]?.routeName || REDIRECT_FALLBACK_ROUTE_NAME;
const tradersRouteName = navigationTraders[0]?.routeName || REDIRECT_FALLBACK_ROUTE_NAME;
const paymentSystemsAndCurrenciesRouteName = navigationPaymentSystemsAndCurrencies[0]?.routeName || REDIRECT_FALLBACK_ROUTE_NAME;
const financeRouteName = navigationFinance[0]?.routeName || REDIRECT_FALLBACK_ROUTE_NAME;
const arbitratorRouteName = navigationArbitrator[0]?.routeName || REDIRECT_FALLBACK_ROUTE_NAME;

const routes = [

  { title: 'Users', routeName: 'users', icon: 'mdi-account-multiple' },



  { title: 'Transactions', routeName: 'transactions', icon: 'mdi-swap-horizontal' },

  { title: 'P2P', routeName: tradersRouteName, icon: 'mdi-swap-vertical' },








  { title: 'Statistics', routeName: statisticsRouteName, icon: 'mdi-text-box' },


  { title: 'Settings', routeName: settingsRouteName, icon: 'mdi-cog' },

  { title: 'Admin management', routeName: 'adminManagement', icon: 'mdi-shield-account-variant' },

  { title: 'Account', routeName: 'account', icon: 'mdi-account' },

  { title: 'Arbitrator', routeName: arbitratorRouteName, icon: 'mdi-account-alert' },
];

export default loginModule.state.currentAdmin.adminStatus > 3 ? routes.filter((elem) => elem.title !== 'Arbitrator') : routes;
