import newhttp from '~/http/new';

export default ({
  state: {
    data: [],
    page: 1,
    pages: 0,
    limit: 10,
  },

  mutations: {
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },
    SET_DATA(state, data) { state.data = data; },
  },

  getters: {
    requestData(state) {
      const {
        limit,
        page,
      } = state;
      return {
        offset: (page - 1) * limit,
        limit,
      };
    },
  },

  actions: {
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];

      const response = await newhttp.get('v1/registration-requests', {
        params: {
          ...getters.requestData,
          ...data,
        },
      });

      const { registrationRequests, total } = response.data;
      commit('SET_DATA', registrationRequests);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async removeRequest(__store, data) {
      await newhttp.post('v1/registration-requests/remove', data);
    },
  },
  namespaced: true,
});
