import http from '~/http/index';

export default ({
  state: {
    data: [],
    filters: {},
    sort: {
      sort: 'createdAt',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
    history: [],
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const defaultData = {
        ...filters,
        page: page - 1,
        limit: itemsPerPage,
      };

      if (!filters.isHidden && !filters.isBlocked && !filters.isDeleted) return { ...defaultData, isEnabled: true };

      return defaultData;
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_HISTORY(state, data) {
      state.history = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit }, params) {
      const gettersData = { ...getters.requestData };
      Object.keys(gettersData).forEach((key) => {
        if (gettersData[key] === null) {
          delete gettersData[key];
        }
      });
      const response = await http.get('p2p/Admin/search', {
        params: {
          ...gettersData,
          ...params,
        },
      });
      const { data, pages } = response.data;
      commit('SET_DATA', data);
      if (pages) {
        commit('SET_PAGES', pages);
      }
    },

    async deleteOffer(__store, data) {
      const response = await http.delete('p2p/Admin/Offer', { data });
      return response.data;
    },

    async editOffer(__store, data) {
      const response = await http.put(`p2p/Admin/Offer/${data.id}`, data);
      return response.data;
    },

    async editOffers(__store, data) {
      const response = await http.put('p2p/Admin/Offers', data);
      return response.data;
    },

    async loadHistory({ commit }, params) {
      const response = await http.get(`p2p/Admin/Offer/${params.id}/history`, params);
      const { data } = response.data;
      commit('SET_HISTORY', data);
    },
  },

  namespaced: true,
});
