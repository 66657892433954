const TEMPLATE_TYPES = {
  BOOLEAN: 0,
  INTEGER: 1,
  TEXT: 2,
};

const TEMPLATE_TYPES_LIST = [
  {
    id: TEMPLATE_TYPES.BOOLEAN,
    caption: 'Boolean',
  },
  {
    id: TEMPLATE_TYPES.INTEGER,
    caption: 'Integer',
  },
  {
    id: TEMPLATE_TYPES.TEXT,
    caption: 'Text',
  },
];

export default {
  TEMPLATE_TYPES,
  TEMPLATE_TYPES_LIST,
};
