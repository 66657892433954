import http from '~/http/new';

export default ({
  state: {
    data: [],
    filters: {},
    sort: {
      sort: 'dateCreated',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
    filterLists: {
      serviceList: [
        { id: 0, caption: 'Service...' },
      ],
    },
  },

  getters: {
    requestData(state, _getters, rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const { id } = rootState.user.user;
      return {
        userId: id,
        ...filters,
        ...sort,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ commit, getters, rootGetters }, { userId, status }) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.get('v1/p2p/requisites', {
        params: {
          userId,
          status,
          ...getters.requestData,
        },
      });
      const { items, total } = response.data;
      commit('SET_DATA', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async createRequisite(__store, data) {
      const response = await http.post('v1/p2p/requisites', data);
      if (!response?.data?.result?.Success) {
        throw new Error(`The requisite has not been created because of Error: "${response?.data?.result?.Error}"`);
      }
      return response.data;
    },

    async editRequisite(__store, data) {
      const response = await http.put(`v1/p2p/requisites/${data.requisitesId}`, data);
      if (!response?.data?.result?.Success) {
        throw new Error(`The requisite has not been edited because of Error: "${response?.data?.result?.Error}"`);
      }
      return response.data;
    },

    async deleteRequisite(__store, { requisitesId, userId }) {
      const { data } = await http.delete(`v1/p2p/requisites/${requisitesId}`, { params: { userId } });
      if (!data?.result?.Success) {
        throw new Error(`The requisite has not been removed because of Error: "${data?.result?.Error}"`);
      }
      return data;
    },
  },
  namespaced: true,
});
