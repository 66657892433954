//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  props: {
    page: { type: Number, default: 1 },
    pages: { type: Number, default: 1 },
    perPage: { type: Boolean, default: false },
    infinitePages: { type: Boolean, default: false },
    compact: { type: Boolean, deafult: false },
  },

  data() {
    return {
      currentPage: this.page,

      isError: false,

      perPageList: [
        { id: 20, caption: '20' },
        { id: 50, caption: '50' },
        { id: 100, caption: '100' },
      ],

      pageRules: [
        (v) => !!Number(v) || '',
        (v) => !this.pages || Number(v) <= this.pages || '',
      ],
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),

    ...mapGetters('common', ['itemsPerPage']),

    pageList() {
      const result = [];
      const { page, pages } = this;
      result.push(1);
      if (pages >= 6 && page >= 4) {
        result.push(null);
      }
      if (page - 1 > 1) {
        result.push(page - 1);
      }
      if (page > 1 && page < pages) {
        result.push(page);
      }
      if (page + 1 < pages) {
        result.push(page + 1);
      }
      if (pages >= 6 && page <= pages - 3) {
        result.push(null);
      }
      if (pages > 1) {
        result.push(pages);
      }
      return result;
    },
  },

  watch: {
    page(value) {
      this.currentPage = value;
    },
  },

  methods: {
    ...mapActions('common', {
      setItemsPerPageAction: 'setItemsPerPage',
    }),

    async setItemsPerPage(value) {
      await this.setItemsPerPageAction(value);
      this.$emit('inputPerPage');
    },

    setError(value) {
      this.isError = value;
    },

    setPage(value) {
      this.$emit('input', value);
    },

    setPrevPage() {
      const { page } = this;
      this.$emit('input', page - 1);
    },

    setNextPage() {
      const { page } = this;
      this.$emit('input', page + 1);
    },

    keydownHandler(event) {
      const { currentPage, isError } = this;
      if (event.code === 'Enter' && !isError) {
        this.setPage(Number(currentPage));
      }
    },

    blurHandler() {
      const { page } = this;
      this.currentPage = page;
    },
  },
};
