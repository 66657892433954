export default [
  {
    path: 'offers',
    name: 'traders-offers',
    component: () => import(/* webpackChunkName: "traders-offers" */ '~/views/TradersOffersAndDeals/Offers/Offers.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderTraders' },
    },
  },

  {
    path: 'deals',
    name: 'traders-deals',
    component: () => import(/* webpackChunkName: "traders-deals" */ '~/views/TradersOffersAndDeals/Deals/Deals.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderTraders' },
    },
  },

  {
    path: 'transactions',
    name: 'traders-transactions',
    component: () => import(/* webpackChunkName: "traders-transactions" */ '~/views/TradersTransactions/Table.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderTraders' },
    },
  },

  {
    path: 'requisites-and-devices',
    name: 'traders-requisites-and-devices',
    component: () => import(/* webpackChunkName: "traders-requisitesAndDevices" */ '~/views/TradersRequisitesAndDevices/TradersRequisitesAndDevices.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderTraders' },
    },
  },

  {
    path: 'fees-and-rates',
    name: 'traders-fees-and-rates',
    component: () => import(/* webpackChunkName: "traders-feesAndRates" */ '~/views/TradersFeesAndRates/TradersFeesAndRates.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderTraders' },
    },
  },

  {
    path: 'currencies-and-payment-methods',
    name: 'traders-currencies-and-payment-methods',
    component: () => import(/* webpackChunkName: "traders-currenciesAndPaymentMethods" */ '~/views/TradersCurrenciesAndPaymentMethods/TradersCurrenciesAndPaymentMethods.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderTraders' },
    },
  },
];
