//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import constant from '~/const';

export default {

  props: {
    userId: { type: [String, Number], default: undefined },
  },

  data() {
    return {
      constant,
      language: '',
      loading: false,
    };
  },

  computed: {
    message() {
      if (this.loading) return '';
      return this.$t('Users language:', { language: this.language });
    },
    isWrongLanguage() {
      const regex = {
        EN: /[a-zA-Z0-9]/,
        RU: /[а-яА-Я0-9]/,
        ZH: /[\u4e00-\u9fff]/, // Китайские иероглифы
        ES: /[a-zA-ZñÑáéíóúüÁÉÍÓÚÜ0-9]/, // Испанский с учетом спецсимволов
        ET: /[a-zA-ZõäöüÕÄÖÜšžŠŽ0-9]/, // Эстонский с учетом спецсимволов
        TR: /[a-zA-ZçÇğĞıİöÖşŞüÜ0-9]/, // Турецкий с учетом спецсимволов
      };

      // Проверяем, соответствует ли первый символ значению локали
      if (
        regex[this.language]
        && this.$attrs.value?.[0] // Регулярное выражение для языка и наличие первого символа
        && !this.loading // Проверка загрузки
        && !regex[this.language].test(this.$attrs.value[0]) // Проверка на соответствие регулярке
      ) {
        return true;
      }
      return false;
    },
    errorMessage() {
      if (this.isWrongLanguage) return this.$t('The comment is in language other than the users locale $language', { language: this.language });
      return '';
    },
  },

  watch: {
    userId() {
      this.getUsersLanguage();
    },
  },

  mounted() {
    this.getUsersLanguage();
  },

  methods: {
    ...mapActions('common', [
      'confirmAction',
    ]),
    ...mapActions('users', [
      'getUser',
    ]),

    async getUsersLanguage() {
      this.loading = true;
      const { language } = await this.getUser(Number(this.$props.userId));
      if (language !== undefined) this.language = this.$t(`const.${this.constant.languages.LANGUAGES_LIST[language].caption}`);
      this.loading = false;
    },
  },
};
