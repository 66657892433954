import checkConstAvailability from '~/utils/check-const-availability';

const KYT_STATUS = {
  DISABLED: 0,
  CHECKING: 1,
  AWAITING: 2,
  OK: 3,
  RETURNING: 4,
  RETURNED: 5,
  ERROR: 6,
};

const KYT_STATUS_LIST = [
  {
    id: KYT_STATUS.DISABLED,
    caption: 'Disabled',
  },
  {
    id: KYT_STATUS.CHECKING,
    caption: 'Checking',
  },
  {
    id: KYT_STATUS.AWAITING,
    caption: 'Awaiting',
  },
  {
    id: KYT_STATUS.OK,
    caption: 'OK',
  },
  {
    id: KYT_STATUS.RETURNING,
    caption: 'Returning',
  },
  {
    id: KYT_STATUS.RETURNED,
    caption: 'Returned',
  },
  {
    id: KYT_STATUS.ERROR,
    caption: 'Error',
  },
];

const LIMIT_PERIOD = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  YEAR: 3,
};

const LIMIT_PERIOD_LIST = [
  {
    id: LIMIT_PERIOD.DAY,
    caption: 'Day',
  },
  {
    id: LIMIT_PERIOD.WEEK,
    caption: 'Week',
  },
  {
    id: LIMIT_PERIOD.MONTH,
    caption: 'Month',
  },
  {
    id: LIMIT_PERIOD.YEAR,
    caption: 'Year',
  },
];

const INDIVIDUAL_SETTINGS_TYPE = {
  DEPOSIT_INTERNAL: 'DepositInternal',
  WITHDRAWAL_INTERNAL: 'WithdrawalInternal',
  DEPOSIT_EXTERNAL: 'DepositExternal',
  WITHDRAWAL_EXTERNAL: 'WithdrawalExternal',
  DEPOSIT_P2P: 'DepositP2P',
  WITHDRAWAL_P2P: 'WithdrawalP2P',
  REFERRAL_BUY: 'ReferralBuy',
  REFERRAL_SELL: 'ReferralSell',
  REFERRAL_P2P: 'ReferralP2P',
  STAKING: 'Staking',
};

const INDIVIDUAL_SETTINGS_TYPE_LIST = [
  {
    id: INDIVIDUAL_SETTINGS_TYPE.DEPOSIT_INTERNAL,
    caption: 'Deposit internal',
  },
  {
    id: INDIVIDUAL_SETTINGS_TYPE.WITHDRAWAL_INTERNAL,
    caption: 'Withdrawal internal',
  },
  {
    id: INDIVIDUAL_SETTINGS_TYPE.DEPOSIT_EXTERNAL,
    caption: 'Deposit',
  },
  {
    id: INDIVIDUAL_SETTINGS_TYPE.WITHDRAWAL_EXTERNAL,
    caption: 'Withdrawal',
  },
  {
    id: INDIVIDUAL_SETTINGS_TYPE.DEPOSIT_P2P,
    caption: 'Deposit P2P',
  },
  {
    id: INDIVIDUAL_SETTINGS_TYPE.WITHDRAWAL_P2P,
    caption: 'Withdrawal P2P',
  },
  {
    id: INDIVIDUAL_SETTINGS_TYPE.REFERRAL_BUY,
    caption: 'Referral buy',
  },
  {
    id: INDIVIDUAL_SETTINGS_TYPE.REFERRAL_SELL,
    caption: 'Referral sell',
  },
  {
    id: INDIVIDUAL_SETTINGS_TYPE.REFERRAL_P2P,
    caption: 'Referral P2P',
  },
  {
    id: INDIVIDUAL_SETTINGS_TYPE.STAKING,
    caption: 'Staking',
  },
].filter((e) => checkConstAvailability(e, { INDIVIDUAL_SETTINGS_TYPE }));

const LIMIT_TYPE_LIST = [
  {
    id: INDIVIDUAL_SETTINGS_TYPE.DEPOSIT_EXTERNAL,
    caption: 'Deposit',
  },
  {
    id: INDIVIDUAL_SETTINGS_TYPE.DEPOSIT_INTERNAL,
    caption: 'Deposit internal',
  },
  {
    id: INDIVIDUAL_SETTINGS_TYPE.DEPOSIT_P2P,
    caption: 'Deposit P2P',
  },
  {
    id: INDIVIDUAL_SETTINGS_TYPE.WITHDRAWAL_EXTERNAL,
    caption: 'Withdrawal',
  },
  {
    id: INDIVIDUAL_SETTINGS_TYPE.WITHDRAWAL_INTERNAL,
    caption: 'Withdrawal internal',
  },
  {
    id: INDIVIDUAL_SETTINGS_TYPE.WITHDRAWAL_P2P,
    caption: 'Withdrawal P2P',
  },
].filter((e) => checkConstAvailability(e, { LIMIT_TYPE: INDIVIDUAL_SETTINGS_TYPE }));

export default {
  LIMIT_PERIOD,
  LIMIT_PERIOD_LIST,
  INDIVIDUAL_SETTINGS_TYPE,
  INDIVIDUAL_SETTINGS_TYPE_LIST,
  LIMIT_TYPE_LIST,
  KYT_STATUS,
  KYT_STATUS_LIST,
};
