import checkConstAvailability from '~/utils/check-const-availability';

const REPORT_TYPE = {
  TOTAL_BALANCES: 'TotalBalances',
  USERS: 'Users',
  PUBLIC_CHAT_MESSAGES: 'PublicChatMessages',
  PRIVATE_CHATS: 'PrivateChats',
  PRIVATE_CHAT_MESSAGES: 'PrivateChatMessages',
  BALANCE_INFO: 'BalanceInfo',
  TRANSACTION_STATS: 'TransactionStats',
  TRANSACTIONS: 'Transactions',
  OPERATIONS: 'Operations',
  TRADES: 'Trades',
  ORDERS: 'Orders',
  USER_ACTIONS: 'UserActions',
  REFERRAL_PROGRAM_INCOMES: 'ReferralProgramIncomes',
  PARAMINING_LOGS: 'StakingLogs',
  STAKING_LOGS: 'StakingLogs',
  BALANCES_INFO: 'BalancesInfo',
  P2P_DEALS: 'P2pDeals',
  P2P_OFFERS: 'P2pOffers',
};

const REPORT_TYPE_IDS = {
  TOTAL_BALANCES: 0,
  USERS: 1,
  PUBLIC_CHAT_MESSAGES: 2,
  PRIVATE_CHATS: 3,
  PRIVATE_CHAT_MESSAGES: 4,
  BALANCE_INFO: 5,
  TRANSACTION_STATS: 6,
  TRANSACTIONS: 7,
  OPERATIONS: 8,
  TRADES: 9,
  ORDERS: 10,
  USER_ACTIONS: 11,
  REFERRAL_PROGRAM_INCOMES: 12,
  PARAMINING_LOGS: 13,
  STAKING_LOGS: 14,
  BALANCES_INFO: 15,
  P2P_DEALS: 16,
  P2P_OFFERS: 17,
};

const P2PAY_REPORT_TYPE = {
  TRADES: 'Trades',
  FEES_STATS: 'FeesStats',
  P2P_STATISTICS: 'P2pStatistics',
};

const P2PAY_REPORT_TYPE_IDS = {
  TRADES: 0,
  FEES_STATS: 1,
  USER_REQUIRES: 3,
  P2P_STATISTICS: 4,
  TRANSACTIONS_STATISTIC: 5,
};

const REPORT_STATUS = {
  NEW: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  ERROR: 3,
};

const P2PAY_REPORT_STATUS = {
  Processing: 0,
  Completed: 1,
  Error: 2,
};

const P2PAY_REPORT_STATUS_LIST = [
  {
    id: P2PAY_REPORT_STATUS.Processing,
    caption: 'Processing',
  },
  {
    id: P2PAY_REPORT_STATUS.Completed,
    caption: 'Completed',
  },
  {
    id: P2PAY_REPORT_STATUS.Error,
    caption: 'Error',
  },
].filter((e) => checkConstAvailability(e, { P2PAY_REPORT_STATUS }));

const REPORT_STATUS_LIST = [
  {
    id: REPORT_STATUS.NEW,
    caption: 'New',
  },
  {
    id: REPORT_STATUS.IN_PROGRESS,
    caption: 'In progress',
  },
  {
    id: REPORT_STATUS.COMPLETED,
    caption: 'Completed',
  },
  {
    id: REPORT_STATUS.ERROR,
    caption: 'Error',
  },
].filter((e) => checkConstAvailability(e, { REPORT_STATUS }));

const LIMIT = 1000000;

const IGNORE_USER_TAG = {
  DEV: 'dev',
  TEST: 'test',
  BOT: 'bot',
};

const IGNORE_USER_TAG_LIST = [
  IGNORE_USER_TAG.DEV,
  IGNORE_USER_TAG.TEST,
  IGNORE_USER_TAG.BOT,
].filter((e) => checkConstAvailability(e, { REPORT_STATUS }, (i) => i));

export default {
  REPORT_TYPE,
  LIMIT,
  REPORT_STATUS,
  REPORT_STATUS_LIST,
  IGNORE_USER_TAG_LIST,
  REPORT_TYPE_IDS,
  P2PAY_REPORT_TYPE_IDS,
  P2PAY_REPORT_TYPE,
  P2PAY_REPORT_STATUS_LIST,
  P2PAY_REPORT_STATUS,
};
