import http from '~/http/index';
import newhttp from '~/http/new';

export default ({
  state: {
    data: [],
    adminNotificationsSettings: [],
    filters: {},
    sort: {
      sort: 'defaultSort',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const base = {
        ...filters,
        ...sort,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        getTotal: true,
      };

      if (filters.blocked === 0) base.blocked = undefined;

      return base;
    },

    appType() {
      return process.env.VUE_APP_EXCLUDE_FEATURE?.split(' ').includes('transactions-old');
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_ADMIN_NOTIFICATIONS_SETTINGS(state, data) {
      state.adminNotificationsSettings = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.get('User/GetAdmins', {
        params: {
          ...getters.requestData,
          ...data,
        },
      });
      const { items, total } = response.data;
      commit('SET_DATA', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async changeAdminStatus(__store, params) {
      return http.put('User/ChangeAdminStatus', null, { params });
    },

    async blockAdmin(__store, AdminId) {
      return http.put('User/BlockAdmin', null, {
        params: { AdminId },
      });
    },

    async unblockAdmin(__store, AdminId) {
      return http.put('User/UnblockAdmin', null, {
        params: { AdminId },
      });
    },

    async createAdmin(__store, params) {
      return http.post('User/CreateAdmin', null, {
        params,
      });
    },

    async loadAdminNotificationsSettings({ commit, getters }) {
      const url = getters.appType ? 'v1/communication/notifications' : 'v2/Communication/GetAdminNotificationsSettings';
      const protocol = getters.appType ? newhttp : http;
      const method = getters.appType ? 'get' : 'post';

      const response = await protocol[method](url);
      const { items } = response.data;
      commit('SET_ADMIN_NOTIFICATIONS_SETTINGS', items);
    },

    removeAdminNotificationsSettings({ getters }, data) {
      const url = getters.appType ? `v1/communication/notifications/${data.id}` : 'v2/Communication/RemoveAdminNotificationsSettings';
      const protocol = getters.appType ? newhttp : http;
      const method = getters.appType ? 'delete' : 'put';
      const requestData = { ...data };

      return protocol[method](url, requestData);
    },

    addAdminNotificationsSettings({ getters }, data) {
      const url = getters.appType ? 'v1/communication/notifications' : 'v2/Communication/AddAdminNotificationsSettings';
      const protocol = getters.appType ? newhttp : http;
      const method = 'post';
      const requestData = { ...data };

      return protocol[method](url, requestData);
    },

    editAdminNotificationsSettings({ getters }, data) {
      const url = getters.appType ? `v1/communication/notifications/${data.id}` : 'v2/Communication/EditAdminNotificationsSettings';
      const protocol = getters.appType ? newhttp : http;
      const method = 'put';
      const requestData = { ...data };

      return protocol[method](url, requestData);
    },
  },

  namespaced: true,
});
