import http from '~/http/index';

export default ({
  state: {
    data: {},
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
  },

  actions: {
    async loadTransactionsSettings({ commit }) {
      const { data } = await http.get('/Options/GetTransactionsSettings');
      await commit('SET_DATA', data);
    },

    async updateTransactionsSettings(__store, data) {
      await http.post('/Options/UpdateTransactionsSettings', data);
    },
  },

  namespaced: true,
});
