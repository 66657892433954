const BALANCE_TYPE = {
  TRADING: 0,
  REFERRAL: 1,
  STAKING: 2,
};

const HISTOGRAM_CURRENCY_LIST = [
  {
    title: 'BTC',
    color: '#1644a2',
  },
  {
    title: 'ETH',
    color: '#3f76e6',
  },
  {
    title: 'LTC',
    color: '#b4cdff',
  },
  {
    title: 'Other',
    color: '#e8f0ff',
    default: true,
  },
];

const BALANCE_DEPOSIT_TYPE = {
  Balance_Limit: 0,
  Balance_Profit: 1,
  Balance_Trust: 2,
  Balance_Hold: 3,
  Balance_Debt: 4,
};

const BALANCE_DEPOSIT_TYPE_LIST = [
  {
    id: BALANCE_DEPOSIT_TYPE.Balance_Limit,
    caption: 'Limit',
    type: 'Balance_Limit',
  },
  {
    id: BALANCE_DEPOSIT_TYPE.Balance_Profit,
    caption: 'Profit',
    type: 'Balance_Profit',
  },
  {
    id: BALANCE_DEPOSIT_TYPE.Balance_Trust,
    caption: 'Trust',
    type: 'Balance_Trust',
  },
  {
    id: BALANCE_DEPOSIT_TYPE.Balance_Hold,
    caption: 'Hold',
    type: 'Balance_Hold',
  },
  {
    id: BALANCE_DEPOSIT_TYPE.Balance_Debt,
    caption: 'Debt',
    type: 'Balance_Debt',
  },
];

export default {
  BALANCE_TYPE,
  HISTOGRAM_CURRENCY_LIST,
  BALANCE_DEPOSIT_TYPE,
  BALANCE_DEPOSIT_TYPE_LIST,
};
