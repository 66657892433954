//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from '~/plugins/i18n';

export default {
  props: {
    items: { type: Array, default: () => [] },
    itemDisabledList: { type: Function, default: () => null },
    itemText: { type: Function, default: (e) => e.caption && i18n.t(`const.${e.caption}`) },
    itemValue: { type: Function, default: (e) => e.id },
    clearable: { type: Boolean, default: true },
    hideDetails: { type: Boolean, default: false },
    multiple: { type: Boolean, default: true },
    hasCustomAction: { type: Boolean, default: false },
    errorMessages: { type: [String, Array], default: () => [] },
    className: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    value: { type: [Array, Object, Number, String, Boolean], default: () => [] },
    label: { type: String, default: '' },
    width: { type: String, default: '' },
    placeholder: { type: String, default: ' ' },
    noDataText: { type: String, deafault: 'autocomplete no data' },
    required: { type: Boolean, default: false },
  },

  computed: {
    style() {
      return {
        width: this.width,
      };
    },
  },

  methods: {
    input(value) {
      this.$emit('input', value);
    },
    blur(value) {
      this.$emit('blur', value);
    },
    change(value) {
      this.$emit('change', value);
    },
    clear(value) {
      this.$emit('clear', value);
    },
    require(v) {
      return !!v || this.$t('This field is required');
    },
  },
};
