export default [
  {
    path: '',
    name: 'arbitrator-awaiting',
    component: () => import(/* webpackChunkName: "arbitrator-awaiting" */ '~/views/Arbitrator/Calls.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderArbitrator' },
    },
  },

  {
    path: 'my-arbitration',
    name: 'arbitrator-my-arbitration',
    component: () => import(/* webpackChunkName: "arbitrator-my-arbitration" */ '~/views/Arbitrator/Deals.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderArbitrator' },
      rootRouteName: 'arbitrator-awaiting',
    },
  },

  {
    path: 'others-arbitration',
    name: 'arbitrator-others-arbitration',
    component: () => import(/* webpackChunkName: "arbitrator-others-arbitration" */ '~/views/Arbitrator/OthersDeals.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderArbitrator' },
      rootRouteName: 'arbitrator-awaiting',
    },
  },

  {
    path: 'others',
    name: 'arbitrator-all-deals',
    component: () => import(/* webpackChunkName: "arbitrator-all-deals" */ '~/views/Arbitrator/DealsOthers/Table.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderArbitrator' },
      rootRouteName: 'arbitrator-awaiting',
    },
  },
];
