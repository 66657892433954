import http from '~/http/new';

export default ({
  state: {
    data: [],
    filters: {},
    page: 1,
    pages: 0,
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const {
        filters,
        page,
      } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const formattedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if (value || value === 0) {
          acc[key] = value;
        }
        return acc;
      }, {});
      return {
        ...formattedFilters,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        // getTotal: true,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.get('v1/p2p/limits', { ...getters.requestData });
      const items = response.data.limits;
      const total = items.length;
      commit('SET_DATA', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },
    async editLimit(__store, data) {
      const response = await http.put('v1/p2p/limits/', data);
      if (!response?.data?.result?.Success) {
        throw new Error(`The limit has not been edited because of Error: "${response?.data?.result?.Error}"`);
      }
      return response.data;
    },

    async addLimit(__store, data) {
      const response = await http.put('v1/p2p/limits/', data);
      if (!response?.data?.result?.Success) {
        throw new Error(`The limit has not been added because of Error: "${response?.data?.result?.Error}"`);
      }
      return response.data;
    },
  },
  namespaced: true,
});
