import i18n from '@/plugins/i18n';
import moment from 'moment';
import http from '~/http/index';
import newhttp from '~/http/new';

export default ({
  state: {
    data: [],
    filters: {},
    sort: {
      sort: 'dateCreated',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state, getters, rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];

      const filterEmptyValues = (obj) => {
        const filtered = {};
        Object.keys(obj).forEach((key) => {
          if (obj[key] !== '') {
            filtered[key] = obj[key];
          }
        });
        return filtered;
      };

      const filteredFilters = filterEmptyValues(filters);

      const baseData = {
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        ...filteredFilters,
        createdFrom: filters.createdFrom ? moment.utc(filters.createdFrom).unix() : undefined,
        createdTo: filters.createdTo ? moment.utc(filters.createdTo).set({ hour: 23, minute: 59, second: 59 }).unix() : undefined,
        ...sort,
      };

      if (getters.appType) {
        return {
          ...baseData,
        };
      }

      return {
        getTotal: true,
        ...sort,
        ...baseData,
      };
    },

    appType() {
      return process.env.VUE_APP_EXCLUDE_FEATURE?.split(' ').includes('transactions-old');
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];

      const url = getters.appType ? 'v1/admin/logs' : 'User/GetAdminLogs';
      const protocol = getters.appType ? newhttp : http;
      const method = getters.appType ? 'get' : 'post';

      const requestData = method === 'get'
        ? { params: { ...getters.requestData, ...data } }
        : { ...getters.requestData, ...data };

      const response = await protocol[method](url, requestData);
      if (!response?.data?.result?.Success) {
        switch (response?.data?.result?.Error) {
          case 'We apologize, looks like something went wrong':
            throw new Error(i18n.t('notification.We apologize, looks like something went wrong'));
          default:
            throw new Error(i18n.t('notification.Unexpected error'));
        }
      }

      const { items, total } = response.data;
      commit('SET_DATA', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },
  },

  namespaced: true,
});
