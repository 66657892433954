import http from '~/http/index';
import { updateData } from '~/utils/common';



export default ({
  state: {
    data: [],
    initialData: [],
    defaultData: [],
    p2pData: [],
    replacements: [],
    language: undefined,
    filters: {},
    sort: {
      sort: 'defaultSort',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state) {
      const { filters } = state;
      return { ...filters };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_INITIAL_DATA(state, data) {
      state.initialData = data;
    },

    SET_DEFAULT_DATA(state, data) {
      state.defaultData = data;
    },

    SET_LOCALISATION_DATA(state, localisationData) {
      state.data = updateData(state.data, localisationData);
      state.language = localisationData[0].language;
      state.defaultData = updateData(state.defaultData, localisationData);
    },

    SET_P2P_MESSAGES_DATA(state, data) {
      state.p2pData = data;
    },

    SET_REPLACEMENTS(state, data) {
      state.replacements = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit }, data) {
      const response = await http.post('v2/Templates/GetNotificationTemplates', {
        ...getters.requestData,
        ...data,
      });
      const { items } = response.data;
      commit('SET_DATA', items);
      commit('SET_INITIAL_DATA', items);
    },

    async loadInitialData({ getters, commit }, data) {
      const response = await http.post('v2/Templates/GetNotificationTemplates', {
        ...getters.requestData,
        ...data,
      });
      const { items } = response.data;
      commit('SET_INITIAL_DATA', items);
    },

    async loadP2PLocalisation({ getters, commit }) {
      const { language } = getters.requestData;
      const response = await http.get(`p2p/Admin/Localisation/All?language=${language}`);
      const { data } = response.data;
      commit('SET_LOCALISATION_DATA', data);
    },

    async updateNotificationTemplate(__store, data) {
      const response = await http.post('v2/Templates/AddNotificationTemplate', data);
      return response.data;
    },

    async loadP2PMessages({ getters, commit }, body) {
      const response = await http.get('p2p/Admin/Localisation/message-defaults', {
        params: {
          ...getters.requestData,
          ...body,
        },
      });
      const { data } = response;
      commit('SET_P2P_MESSAGES_DATA', data);
    },

    async loadReplacements({ commit }) {
      const response = await http.get('p2p/Admin/Localisation/message-replacements');
      const { data } = response;
      commit('SET_REPLACEMENTS', data);
    },

    async updateP2PNotification(__store, data) {
      const response = await http.put(`p2p/Admin/Localisation/${data.id}`, data);
      return response.data;
    },

    async deleteMessage(__store, id) {
      const response = await http.delete(`p2p/Admin/Localisation/${id}`);
      return response.data;
    },

    async createMessage(__store, data) {
      const response = await http.post('p2p/Admin/Localisation', data);
      return response.data;
    },

    async updateMessage(__store, data) {
      const response = await http.put(`p2p/Admin/Localisation/${data.id}`, data);
      return response.data;
    },
  },

  namespaced: true,
});
