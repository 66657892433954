import moment from 'moment';
import http from '~/http/index';
import newhttp from '~/http/new';
import constant from '~/const';
import storeUtils from '~/utils/storeUtils';

export default ({
  state: {
    transactionsHistory: [],
    filters: {},
    // sort: {
    //   sort: 'dateCreated',
    //   sortDesc: true,
    // },
    page: 1,
    pages: 0,
    filterLists: {
      List: [
        { id: 0, caption: '' },
      ],
    },
  },

  getters: {
    requestData(state, _getters, rootState, rootGetters) {
      const { filters, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const { id } = rootState.user.user;
      return {
        userId: [id],
        ...filters,
        createdFrom: filters.createdFrom ? moment.utc(filters.createdFrom).unix() : undefined,
        createdTo: filters.createdTo ? moment.utc(filters.createdTo).set({ hour: 23, minute: 59, second: 59 }).unix() : undefined,
        // ...sort,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        // getTotal: true,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    // SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_TRANSACTIONS_HISTORY(state, data) {
      const transactionMethodTypeList = constant.operations.TRANSACTION_METHOD_TYPE_LIST;
      state.transactionsHistory = data.map((transaction) => {
        return {
          ...transaction,
          amount: storeUtils.inOutAmount(transaction.amount, transaction.side),
          transactionMethodType: storeUtils.getCaption(
            transactionMethodTypeList, transaction.transactionMethodType,
          ),
          transactionMethodTypeEnum: transaction.transactionMethodType,
        };
      });
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    // setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async getTransactionsHistory({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await newhttp.get('v1/transactions', {
        params: {
          ...getters.requestData,
          ...data,
        },
      });
      const { items, total } = response.data;
      commit('SET_TRANSACTIONS_HISTORY', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async getOperation(__store, data) {
      const response = await http.post('v2/Trading/GetOperation', data);
      return response.data;
    },

    async getTransactionStatusHistory(__store, data) {
      const response = await http.post('Transaction/GetTransactionStatusHistory', data);
      return response.data;
    },

    async adminProcessingTransaction(__store, data) {
      const response = await http.put('Transaction/AdminProcessingTransaction', data);
      return response.data;
    },

    async adminConfirmTransaction(__store, data) {
      const response = await http.put('Transaction/AdminConfirmTransaction', data);
      return response.data;
    },

    async adminCompleteTransaction(__store, data) {
      const response = await http.put('Transaction/AdminCompleteTransaction', data);
      return response.data;
    },

    async adminCancelTransaction(__store, data) {
      const response = await http.put('Transaction/AdminCancelTransaction', data);
      return response.data;
    },

    async adminDeclineTransaction(__store, data) {
      const response = await http.put('Transaction/AdminDeclineTransaction', data);
      return response.data;
    },

    async adminNeedContactTransaction(__store, data) {
      const response = await http.put('Transaction/AdminNeedContactTransaction', data);
      return response.data;
    },

    async adminCheckTransaction(__store, data) {
      const response = await http.put('Transaction/AdminCheckTransaction', data);
      return response.data;
    },

    async adminCancelCompletedDeposit(__store, data) {
      const response = await http.put('Transaction/AdminCancelCompletedDeposit', data);
      return response.data;
    },

    async updateTransactionRequisites(__store, data) {
      const response = await http.post('Transaction/UpdateTransactionRequisites', data);
      return response.data;
    },
  },

  namespaced: true,
});
