import checkConstAvailability from '~/utils/check-const-availability';

const ADMIN_ACTIVE_STATUSES = {
  ACTIVE: 0,
  BLOCKED: 1,
};

const ADMIN_ACTIVE_STATUSES_LIST = [
  {
    id: ADMIN_ACTIVE_STATUSES.ACTIVE,
    caption: 'Active',
  },
  {
    id: ADMIN_ACTIVE_STATUSES.BLOCKED,
    caption: 'Blocked',
  },
].filter((e) => checkConstAvailability(e, { ADMIN_ACTIVE_STATUSES }));

const ADMIN_STATUS = {
  ADMIN: 0,
  SUPER_ADMIN: 1,
  SUPPORT: 2,
  ARBITRATOR: 3,
  // OWNER: 3,
  // OPERATOR: 4,
  // MARKETING: 5,
  // SECURITY: 7,
  // FINANCIAL_DEPARTMENT: 8,
  // AGENT: 9,
  // VERIFIER: 10,
  // FINANCIER: 11,
};

const ADMIN_STATUS_LIST = [
  {
    id: ADMIN_STATUS.ADMIN,
    caption: 'Admin',
  },
  {
    id: ADMIN_STATUS.SUPER_ADMIN,
    caption: 'Super admin',
  },
  {
    id: ADMIN_STATUS.SUPPORT,
    caption: 'Support',
  },
  {
    id: ADMIN_STATUS.ARBITRATOR,
    caption: 'Admin-arbitrator',
  },
].filter((e) => checkConstAvailability(e, { ADMIN_STATUS }));

const ADMIN_NOTIFICATION_TYPE_P2PAY = {
  GENERAL: 0,
  OFFERS_NOT_FOUND: 1,
  REQUISITES_NOT_FOUND: 2,
  CHAT: 3,
  BALANCE_CONTROL: 4,
  BALANCE_ERROR: 5,
  WITHDRAWAL_ERROR: 6,
};

const ADMIN_NOTIFICATION_TYPE_P2PAY_LIST = [
  {
    id: ADMIN_NOTIFICATION_TYPE_P2PAY.GENERAL,
    caption: 'General',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE_P2PAY.OFFERS_NOT_FOUND,
    caption: 'Offers not found',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE_P2PAY.REQUISITES_NOT_FOUND,
    caption: 'Requisites not found',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE_P2PAY.CHAT,
    caption: 'Chat',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE_P2PAY.BALANCE_CONTROL,
    caption: 'Balance control',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE_P2PAY.BALANCE_ERROR,
    caption: 'Balance error',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE_P2PAY.WITHDRAWAL_ERROR,
    caption: 'Withdrawal error',
  },
];

const ADMIN_NOTIFICATION_TYPE = {
  NEW_WITHDRAWAL: 0,
  NEW_DEPOSIT: 1,
  SIGN_UP: 2,
  REDEEM_CODE: 3,
  NEW_TRANSACTION_ERROR: 4,
  AWAITING_MANUAL_CHECK_TRANSACTION: 5,
  VERIFICATION: 6,
  NODE_ERROR: 7,
  MULTIWALLET_ERROR: 8,
  SUPPORT: 9,
  P2P_ARBITR: 10,
  TRANSACTION_CHECK: 11,
  NODE_BALANCE: 12,
  BALANCE_WARNING: 13,
  TRANSACTION_FREEZE: 14,
  RATES_ERROR: 15,
  STORE_WAITS_APPROVAL: 16,
};

const ADMIN_NOTIFICATION_TYPE_LIST = [
  {
    id: ADMIN_NOTIFICATION_TYPE.NEW_WITHDRAWAL,
    caption: 'New withdrawal',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.NEW_DEPOSIT,
    caption: 'New deposit',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.SIGN_UP,
    caption: 'Sign up',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.REDEEM_CODE,
    caption: 'Redeem code',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.NEW_TRANSACTION_ERROR,
    caption: 'New transaction error',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.AWAITING_MANUAL_CHECK_TRANSACTION,
    caption: 'Awaiting manual check transaction',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.VERIFICATION,
    caption: 'Verification',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.NODE_ERROR,
    caption: 'Node error',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.MULTIWALLET_ERROR,
    caption: 'Multiwallet error',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.SUPPORT,
    caption: 'Support',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.P2P_ARBITR,
    caption: 'P2P arbitr',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.TRANSACTION_CHECK,
    caption: 'Transaction Check',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.NODE_BALANCE,
    caption: 'Node Balance',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.BALANCE_WARNING,
    caption: 'Balance Warning',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.TRANSACTION_FREEZE,
    caption: 'Transaction Freeze',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.RATES_ERROR,
    caption: 'Rates Error',
  },
  {
    id: ADMIN_NOTIFICATION_TYPE.STORE_WAITS_APPROVAL,
    caption: 'Store Waits Approval',
  },
].filter((e) => checkConstAvailability(e, { ADMIN_NOTIFICATION_TYPE }));

export default {
  ADMIN_STATUS,
  ADMIN_STATUS_LIST,
  ADMIN_NOTIFICATION_TYPE,
  ADMIN_NOTIFICATION_TYPE_LIST,
  ADMIN_NOTIFICATION_TYPE_P2PAY,
  ADMIN_NOTIFICATION_TYPE_P2PAY_LIST,
  ADMIN_ACTIVE_STATUSES,
  ADMIN_ACTIVE_STATUSES_LIST,
};
