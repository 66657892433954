import traders from '~/const/traders';
import chat from '~/const/chat';
import settings from './settings';
import currencies from './currencies';
import notifications from './notifications';
import orders from './orders';
import users from './users';
import user from './user';
import api from './api';
import operations from './operations';
import reports from './reports';
import admin from './admin';
import balance from './balance';
import transactions from './transactions';
import paramining from './paramining';
import p2p from './p2p';
import statistics from './statistics';
import frontConfig from './frontConfig';
import merchants from './merchants';
import languages from './languages';
import templates from './templates';
import adjectives from './adjectives';
import kytSource from './kytSource';

export default {
  settings,
  currencies,
  notifications,
  orders,
  users,
  user,
  api,
  operations,
  reports,
  admin,
  balance,
  transactions,
  paramining,
  p2p,
  statistics,
  frontConfig,
  merchants,
  traders,
  chat,
  languages,
  templates,
  adjectives,
  kytSource,
};
