import checkConstAvailability from '~/utils/check-const-availability';

const EXCHANGE_URL = process.env.VUE_APP_EXCHANGE_URL;

const VERIFICATION_TYPE = {
  MANUAL: 0,
  AUTO: 1,
  MANUAL_AUTO: 2,
  AUTO_MANUAL: 3,
};

const VERIFICATION_TYPE_LIST = [
  {
    id: VERIFICATION_TYPE.MANUAL,
    caption: 'Manual',
  },
  {
    id: VERIFICATION_TYPE.AUTO,
    caption: 'Auto',
  },
  {
    id: VERIFICATION_TYPE.MANUAL_AUTO,
    caption: 'Manual auto',
  },
  {
    id: VERIFICATION_TYPE.AUTO_MANUAL,
    caption: 'Auto manual',
  },
].filter((e) => checkConstAvailability(e, { VERIFICATION_TYPE }));

const VERIFICATION_AUTO_TYPE = {
  SAMSUB: 0,
  VERIFF: 1,
};

const VERIFICATION_AUTO_TYPE_LIST = [
  {
    id: VERIFICATION_AUTO_TYPE.SAMSUB,
    caption: 'SumSub',
  },
  {
    id: VERIFICATION_AUTO_TYPE.VERIFF,
    caption: 'Veriff',
  },
].filter((e) => checkConstAvailability(e, { VERIFICATION_AUTO_TYPE }));

const TECHNICAL_WORK_TYPE = {
  STOP_P2P: 'stopP2P',
  STOP_REGISTRATION: 'stopRegistration',
  STOP_TRADE: 'stopTrade',
  STOP_TRANSACTION_CONFIRM: 'stopTransactionConfirm',
  STOP_TRANSACTIONS: 'stopTransactions',
};

const TECHNICAL_WORK_TYPE_LIST = [
  {
    id: TECHNICAL_WORK_TYPE.STOP_P2P,
    caption: 'Stop P2P',
  },
  {
    id: TECHNICAL_WORK_TYPE.STOP_REGISTRATION,
    caption: 'Stop registration',
  },
  {
    id: TECHNICAL_WORK_TYPE.STOP_TRADE,
    caption: 'Stop trading',
  },
  {
    id: TECHNICAL_WORK_TYPE.STOP_TRANSACTION_CONFIRM,
    caption: 'Stop transaction confirm',
  },
  {
    id: TECHNICAL_WORK_TYPE.STOP_TRANSACTIONS,
    caption: 'Stop transactions',
  },
].filter((e) => checkConstAvailability(e, { TECHNICAL_WORK_TYPE }));

export default {
  EXCHANGE_URL,
  VERIFICATION_TYPE,
  VERIFICATION_TYPE_LIST,
  VERIFICATION_AUTO_TYPE,
  VERIFICATION_AUTO_TYPE_LIST,
  TECHNICAL_WORK_TYPE,
  TECHNICAL_WORK_TYPE_LIST,
};
