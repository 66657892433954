import newhttp from '~/http/new';

export default ({
  state: {
    data: [],
    page: 1,
    pages: 0,
  },

  getters: {
    requestData(state) {
      const { filters, sort } = state;
      return {
        ...filters,
        ...sort,
        limit: 10,
      };
    },
  },

  mutations: {
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },
  },

  actions: {
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, dataObj) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await newhttp.get('v1/arbitrator/trades/me', {
        params: {
          ...getters.requestData,
          ...dataObj,
        },
      });
      const { data, total } = response.data;
      commit('SET_DATA', data);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async confirmTrade(__store, data) {
      const response = await newhttp.post(`v1/arbitrator/trades/${data}/confirmation`, { id: data });
      return response.data;
    },

    async cancelTrade(__store, data) {
      const response = await newhttp.post(`v1/arbitrator/trades/${data}/cancellation`, { id: data });
      return response.data;
    },
  },

  namespaced: true,
});
