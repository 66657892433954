import newhttp from '~/http/new';

export default ({
  state: {
    data: [],
    filters: {},
    page: 1,
    pages: 0,
  },

  getters: {
    requestData(state) {
      const { filters } = state;
      // const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...filters,
        // ...sort,
        // offset: (page - 1) * itemsPerPage,
        // limit: itemsPerPage,
      };
    },

    reportData(state) {
      const { filters } = state;
      return {
        ...filters,
        fromDate: filters.fromDate ? String(filters.fromDate) : undefined,
        toDate: filters.toDate ? String(filters.toDate) : undefined,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit }, data) {
      const response = await newhttp.get('v1/statistics/trades', {
        params: {
          ...getters.requestData,
          ...data,
        },
      });
      commit('SET_DATA', response.data);
      // if (total) {
      //   const pages = Math.ceil(total / itemsPerPage);
      //   commit('SET_PAGES', pages);
      // }
    },
  },

  namespaced: true,
});
