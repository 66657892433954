import i18n from '@/plugins/i18n';
import http from '~/http/index';
import newhttp from '~/http/new';
import constant from '~/const';
import { setStorageIsDarkTheme } from '~/localStorage';

export default ({
  state: {
    successNotification: '',
    errorNotification: '',
    showNavigation: false,
    generalLoader: false,
    generalProgress: false,
    generalConfirm: {
      showModal: false,
      title: '',
      text: '',
      hasPrompt: false,
      promptRequired: false,
      promptLabel: '',
      promptValue: '',
      callback: null,
      hasOkButton: true,
      hasCancelButton: true,
    },
    adminSettings: {
      isDarkTheme: false,
      itemsPerPage: 20,
      locale: 'en',
      savedFilters: {},
    },
    status: {},
    languages: [],
  },

  getters: {
    itemsPerPage(state) {
      const { itemsPerPage } = state.adminSettings;
      return itemsPerPage;
    },

    exchangeLanguageList(state) {
      const { LANGUAGE_LIST } = constant.notifications;
      const { languageList } = state.status;
      return LANGUAGE_LIST.filter((e) => languageList.includes(e.id.toLowerCase()));
    },

    getLanguageById: (state) => (id) => {
      const { languageList } = state.status;
      if (languageList && languageList.length) {
        return languageList[id];
      }
      return '';
    },

    appType() {
      return process.env.VUE_APP_EXCLUDE_FEATURE?.split(' ').includes('transactions-old');
    },
  },

  mutations: {
    SET_SUCCESS_NOTIFICATION(state, value) {
      state.successNotification = value;
    },

    SET_ERROR_NOTIFICATION(state, value) {
      state.errorNotification = value;
    },

    SET_SHOW_NAVIGATION(state, value) {
      state.showNavigation = value;
    },

    SET_GENERAL_LOADER(state, value) {
      state.generalLoader = value;
    },

    SET_GENERAL_PROGRESS(state, value) {
      state.generalProgress = value;
    },

    SET_GENERAL_CONFIRM(state, data) {
      state.generalConfirm = data;
    },

    SET_GENERAL_CONFIRM_MODAL(state, value) {
      state.generalConfirm.showModal = value;
    },

    SET_IS_DARK_THEME(state, value) {
      state.adminSettings.isDarkTheme = value;
    },

    SET_ITEMS_PER_PAGE(state, value) {
      state.adminSettings.itemsPerPage = value;
    },

    SET_LOCALE(state, value) {
      localStorage.setItem('locale', value);
      state.adminSettings.locale = value;
    },

    SET_SAVED_FILTERS(state, data) {
      state.adminSettings.savedFilters = data;
    },

    SET_STATUS(state, data) {
      state.status = data;
    },

    SET_LANGUAGES(state, data) {
      state.languages = data;
    },
  },

  actions: {
    setSuccessNotification({ commit }, value) { commit('SET_SUCCESS_NOTIFICATION', value); },

    setErrorNotification({ commit }, value) { commit('SET_ERROR_NOTIFICATION', value); },

    setShowNavigation({ commit }, value) { commit('SET_SHOW_NAVIGATION', value); },

    setGeneralLoader({ commit }, value) { commit('SET_GENERAL_LOADER', value); },

    setGeneralProgress({ commit }, value) { commit('SET_GENERAL_PROGRESS', value); },

    async confirmAction({ commit }, {
      title = '',
      text = '',
      hasPrompt = false,
      promptRequired = false,
      promptLabel = '',
      promptValue = '',
      hasOkButton = true,
      hasCancelButton = true,
      callback = () => {},
      cancelCallback = () => {},
    }) {
      await commit('SET_GENERAL_CONFIRM', {
        showModal: true,
        title,
        text,
        hasPrompt,
        promptRequired,
        promptLabel,
        promptValue,
        hasOkButton,
        hasCancelButton,
        callback,
        cancelCallback,
      });
    },

    setGeneralConfirmModal({ commit }, value) {
      commit('SET_GENERAL_CONFIRM_MODAL', value);
    },

    callGeneralConfirmCallback({ state }, payload) {
      const { callback } = state.generalConfirm;
      callback(payload);
    },

    callGeneralConfirmCancelCallback({ state }, payload) {
      const { cancelCallback } = state.generalConfirm;
      cancelCallback(payload);
    },

    setAdminSettings({ commit }, data) {
      const {
        isDarkTheme,
        itemsPerPage,
        locale,
        savedFilters,
      } = data;
      if (isDarkTheme) {
        commit('SET_IS_DARK_THEME', isDarkTheme);
        setStorageIsDarkTheme(isDarkTheme);
      }
      if (itemsPerPage) { commit('SET_ITEMS_PER_PAGE', itemsPerPage); }
      if (locale) { commit('SET_LOCALE', locale); }
      if (savedFilters) { commit('SET_SAVED_FILTERS', savedFilters); }
    },

    async setIsDarkTheme({ commit, dispatch }, value) {
      setStorageIsDarkTheme(value);
      await commit('SET_IS_DARK_THEME', value);
      await dispatch('updateAdminSettings');
    },

    async setLocale({ commit, dispatch }, value) {
      await commit('SET_LOCALE', value);
      await dispatch('updateAdminSettings');
    },

    async setItemsPerPage({ commit, dispatch }, value) {
      await commit('SET_ITEMS_PER_PAGE', value);
      await dispatch('updateAdminSettings');
    },

    async setSavedFilters({ state, commit, dispatch }, { key, value }) {
      const data = {
        ...state.adminSettings.savedFilters,
        [key]: value,
      };
      await commit('SET_SAVED_FILTERS', data);
      await dispatch('updateAdminSettings');
    },

    async updateAdminSettings({ state }) {
      const data = {
        settings: state.adminSettings,
      };
      await http.put('Login/UpdateAdminSettings', data);
    },

    async getStatus({ commit }) {
      const { data } = await http.get('Options/GetStatus');
      commit('SET_STATUS', data);
      commit('SET_LANGUAGES', data.languageList);
    },

    async changeUserLanguage({ getters }, data) {
      const url = getters.appType ? `v1/user/${data.id}/language` : 'v2/User/UpdateUserLocalizationSettings';
      const protocol = getters.appType ? newhttp : http;
      const response = await protocol.put(url, data);

      if (!response?.data?.result?.Success) {
        switch (response?.data?.result?.Error) {
          case 'Language is not allowed':
            throw new Error(i18n.t('notification.Language is not allowed'));
          default:
            throw new Error(i18n.t('notification.Unexpected error'));
        }
      }
    },
  },

  namespaced: true,
});
