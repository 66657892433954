export default [
  { title: 'Technical', routeName: 'settings-technical' },

  { title: 'Email', routeName: 'settings-email' },



  { title: 'Watermark', routeName: 'settings-watermark' },

];
