//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';

export default {
  props: {
    clearable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    label: { type: String, default: '' },
    width: { type: String, default: '' },
    value: { type: String, default: '' },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    caption: {
      get() {
        return this.value ? moment(this.value).format('DD.MM.YYYY') : '';
      },
      set(value) {
        if (moment(value, 'DD.MM.YYYY').isValid() && value.length === 10) {
          const date = moment(value, 'DD.MM.YYYY');
          this.$emit('input', date.format('YYYY-MM-DD'));
        } else if (value.length >= 3 && !value.includes('.')) {
          this.$emit('error', this.$t('common.Incorrect format!'));
        }
      },
    },
    isClearable() {
      const { clearable, value } = this;
      return clearable && value;
    },

    style() {
      return {
        width: this.width,
      };
    },
  },

  methods: {
    close() {
      this.isOpen = false;
    },

    clear() {
      this.$emit('input', '');
    },

    update(value) {
      this.$emit('input', value);
      this.isOpen = false;
    },
  },
};
