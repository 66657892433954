//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';

export default {
  props: {
    clearable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    label: { type: String, default: '' },
    width: { type: String, default: '' },
    value: { type: Number, default: null },
  },

  data() {
    return {
      isOpen: false,
      dateValue: this.value ? moment.unix(this.value).format('YYYY-MM-DD') : '',
      timeValue: this.value ? moment.unix(this.value).format('HH:mm') : '',
    };
  },

  computed: {
    caption() {
      if (this.value) {
        return moment.unix(this.value).format('DD.MM.YY HH:mm');
      }
      return ' ';
    },

    isClearable() {
      return this.clearable && !!this.value;
    },

    style() {
      return {
        width: this.width,
      };
    },
  },

  watch: {
    value(val) {
      this.dateValue = val ? moment.unix(val).format('YYYY-MM-DD') : '';
      this.timeValue = val ? moment.unix(val).format('HH:mm') : '';
    },
  },

  methods: {
    close() {
      this.isOpen = false;
    },

    clearAll() {
      this.$emit('input', null);
    },

    updateDate(date) {
      this.dateValue = date;
      this.updateValue();
    },

    updateTime(time) {
      this.timeValue = time;
      this.updateValue();
    },

    updateValue() {
      if (this.dateValue && this.timeValue) {
        const dateTime = `${this.dateValue} ${this.timeValue}`;
        const timestamp = moment(dateTime, 'YYYY-MM-DD HH:mm').unix();
        this.$emit('input', timestamp);
      }
    },
  },
};
