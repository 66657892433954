import i18n from '~/plugins/i18n';
import newhttp from '~/http/new';

export default ({
  state: {
    messages: [],
    page: 1,
    pages: 0,
    perPage: 50,
    socket: null,
  },

  mutations: {
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },
    SET_MESSAGES(state, data) { state.messages = data; },

    ADD_MESSAGE(state, message) {
      state.messages.unshift(message);
    },
  },

  getters: {
    requestData(state) {
      const {
        page,
        perPage,
      } = state;
      return {
        offset: (page - 1) * perPage,
        limit: perPage,
      };
    },

    hasMoreMessages(state) {
      return state.messages.length === state.page * state.perPage;
    },

    getSocketAddress() {
      return process.env.VUE_APP_SOCKET_ADDRESS;
    },
  },

  actions: {
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ commit, getters, rootGetters }, id) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await newhttp.get(`v1/communication/groupchat/${id}/messages`, {
        params: {
          ...getters.requestData,
          id,
        },
      });
      const { items, total } = response.data;
      commit('SET_MESSAGES', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async initiateSocket({
      state,
      getters,
      dispatch,
      commit,
    }) {
      state.socket = new WebSocket(getters.getSocketAddress);
      state.socket.onopen = () => {
        dispatch('sendAuthRequest');
      };

      const originalTitle = document.title;
      let newMessageReceived = false;

      document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          if (newMessageReceived) {
            document.title = i18n.t('New message');
          }
        } else {
          document.title = originalTitle;
          newMessageReceived = false;
        }
      });

      state.socket.onmessage = (event) => {
        const res = JSON.parse(event.data);
        let vars = {};
        if (res.data.Variables) {
          vars = JSON.parse(res.data.Variables);
        }
        if (res.type === 'Chat') {
          const newMessage = {
            id: vars.MessageId,
            chatMemberType: vars.MemberType,
            dateCreated: res.timestamp,
            files: vars.Files,
            username: res.data.Sender,
            text: res.data.Message,
          };
          commit('ADD_MESSAGE', newMessage);

          if (document.hidden) {
            newMessageReceived = true;
            document.title = i18n.t('New message');

            const newMessageSound = new Audio('https://notificationsounds.com/storage/sounds/file-sounds-1148-juntos.ogg');
            newMessageSound.play();
          }
        }
      };

      state.socket.onerror = (error) => {
        throw new Error(`WebSocket error: ${error}`);
      };
    },

    sendAuthRequest({ state }) {
      const token = localStorage.getItem('accessToken');
      if (state.socket && state.socket.readyState === WebSocket.OPEN) {
        const authRequest = {
          Authorization: token,
        };
        state.socket.send(JSON.stringify(authRequest));
      }
    },

    async sendMessage({ commit }, data) {
      const content = {
        id: data.id,
        message: data.message,
        files: data.files,
        receiverId: data.receiverId,
      };

      await newhttp.post(`v1/communication/groupchat/${data.id}/messages`, content);

      const newMessage = {
        chatMemberType: 2,
        dateCreated: Math.floor(Date.now() / 1000),
        files: data.files,
        username: 'Admin',
        text: data.message,
        receiverName: data.receiverName,
      };
      commit('ADD_MESSAGE', newMessage);
    },
  },

  namespaced: true,
});
