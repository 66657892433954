import i18n from '@/plugins/i18n';
import http from '~/http/new';

export default ({
  state: {
    filters: {},
    data: [],
    currencies: [],
    sources: [],
  },

  getters: {
    requestData(state) {
      const { filters } = state;
      const formattedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});
      return { ...formattedFilters };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_SOURCES(state, data) {
      state.sources = data;
    },
    SET_CURRENCIES(state, data) {
      state.currencies = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },

    async loadData({ commit, getters }) {
      const response = await http.get('/v1/p2p/rate/settings', {
        params: {
          ...getters.requestData,
        },
      });
      const { result, settings } = response.data;
      if (result?.Success) {
        commit('SET_DATA', settings);
      }
    },
    async loadRateCurrencies({ commit }) {
      const response = await http.get('/v1/p2p/currencies/active');
      const { result, items } = response.data;
      if (result?.Success) {
        commit('SET_CURRENCIES', items);
      }
    },
    async loadRateSources({ commit }) {
      const response = await http.get('/v1/p2p/rate/sources');
      const { result, sources } = response.data;
      if (result?.Success) {
        commit('SET_SOURCES', sources);
      }
    },

    async editRates(__store, data) {
      const response = await http.put('/v1/p2p/rate/settings', data);
      if (!response?.data?.result?.Success) {
        switch (response?.data?.result?.Error) {
          case 'RateNotFound':
            throw new Error(i18n.t('Couldn\'t find course'));
          case 'NullSource':
            throw new Error(i18n.t('You have not selected any payment method for the source'));
          default:
            throw new Error(i18n.t('Unexpected error'));
        }
      }
      return response.data;
    },

    async deleteRate(__store, { baseCurrency, quoteCurrency }) {
      const response = await http.delete('/v1/p2p/rate/settings', { data: { baseCurrency, quoteCurrency } });
      if (!response?.data?.result?.Success) {
        throw new Error(`The Rates has not been deleted because of Error: "${response?.data?.result?.Error}"`);
      }
      return response.data;
    },
  },

  namespaced: true,
});
